import styled from 'styled-components'

export const NotificationsDropTarget = styled.span`
  width: 400px;
  height: 100%;
  position: absolute;
  right: 0;
  z-index: -1;
`

// export const HamburgerDropTarget = styled.span`
//   width: 64px;
//   height: 100%;
//   position: absolute;
//   right: 0;
//   z-index: -1;
// `

