import { Hero } from 'shared/models'
import { 
  Card, 
  FrameImage, 
  skinImage,
} from 'components/HeroCard/index.styled'

export default function HeroCardPlaceholder({ name, size, margin, isTurn=false}) {

  return (
    <Card 
      name={name || ''}
      size={size}
      margin={margin}
      isTurn={isTurn} 
      >
      <FrameImage src={skinImage[Hero.RANK.UNKNOWN]} />
    </Card>
  )
}
//          
