import { useRef } from 'react'
import Box from '../Box'
import Wallet from '../Wallet'
import HeaderButton from './HeaderButton'
import { HeroIcon, BattleIcon, TournamentIcon, PlayerIcon, LeagueIcon, BadgeIcon, ItemsIcon, HelpIcon } from '..'
import NotificationsButton from './NotificationsButton'
import { NotificationsDropTarget } from './DropTarget'
import { ROUTE_STORE, ROUTE_HEROES, ROUTE_BATTLES, ROUTE_BADGES, ROUTE_LEAGUE, ROUTE_HELP, ROUTE_TOURNAMENTS, ROUTE_PLAYERS } from './index'

export default function HeaderMenuScreen({ onNotificationsClick }) {
  const notificationTarget = useRef()
  return (
    <>
      <Box
        direction='row'
        align='center'
        justify='between'
        gap='small'
      >
        <HeaderButton href={ROUTE_HEROES} Icon={HeroIcon} tip='Heroes' />
        <HeaderButton href={ROUTE_BATTLES} Icon={BattleIcon} tip='Battles' />
        <HeaderButton href={ROUTE_TOURNAMENTS} Icon={TournamentIcon} tip='Tournaments' />
        <HeaderButton href={ROUTE_PLAYERS} Icon={PlayerIcon} tip='Players' />
        <HeaderButton href={ROUTE_BADGES} Icon={BadgeIcon} tip='Cup' />
        <HeaderButton href={ROUTE_LEAGUE} Icon={LeagueIcon} tip='League' />
        <HeaderButton href={ROUTE_STORE} Icon={ItemsIcon} tip='Store' />
        <Wallet />
        <HeaderButton href={ROUTE_HELP} Icon={HelpIcon} tip='Help' />
        <NotificationsButton 
          dropTarget={notificationTarget.current} 
          onClick={onNotificationsClick} 
        />
      </Box>
      <NotificationsDropTarget ref={notificationTarget} />
    </>
  )
}
