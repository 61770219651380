import styled from 'styled-components'
import { Tip, PotionIcon } from 'components'
import { signChainbornBehaviourMessage } from 'shared/wallet'
import { useGetStoreItems, useGetConfig } from 'shared/apollo'
import { useAlert, attackLoadingState, walletState } from 'shared/state'
import { useRecoilValue, useRecoilState } from 'recoil'
import { login, executeBattleTurn } from 'shared/api'
import { notify, getItemID, isEqualHero } from 'shared/utils'
import { _useBattleItem } from 'shared/contract'
import { SESSION_ERROR_MESSAGE, POTIONBELT_MAX_POTIONS } from 'shared/constants'

export default function PotionBelt({ battle, hero }) {
  const wallet = useRecoilValue(walletState)
  const { config } = useGetConfig()
  const [, setAlert ] = useAlert()
  const [, setLoading ] = useRecoilState(attackLoadingState(battle?.bid))
  const { loading, error, items: storeItems } = useGetStoreItems()

  if  (loading || error) return null

  const beltItems = (hero?.items || [])
    .filter(item => {
      return item.name.toLowerCase().indexOf('potion') >= 0
    })
    .map(item => {
      const storeItem = storeItems.filter(si => getItemID(si) === getItemID(item))[0]
      const _item = Object.assign({}, item)
      _item.store = storeItem
      return _item
    })

  const handleUsePotion = async (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    if (!item) return
    if (!battle?.started || battle?.finished) return
    if (!wallet?.address) return
    if (wallet?.address !== hero?.owner) return
    if(!isEqualHero(battle.turn, hero)) return
    const attacker = hero === battle.challenger ? battle.challenger : battle.challenged
    const defender = hero === battle.challenger ? battle.challenged : battle.challenger
    setLoading(true)
    const payload = { bid: battle.bid, account: wallet, action: 'item', item: [item.token_address, item.token_id] }
    // Try to execute the battleturn first
    try {
      await executeBattleTurn(payload)
      await _useBattleItem({
        contractAddress: config.CHAINBORN_GAME_CONTROLLER_ADDRESS,
        bid: battle.bid,
        item,
        user_hero: attacker,
        target_hero: attacker  
      }) 
      await notify(config.NETWORK_NAME, attacker.owner, defender.owner, item?.store?.item_category, `${attacker.name} used an item!`, `/battles/${battle.bid}`)
    } catch(e) {
      if (e.message === SESSION_ERROR_MESSAGE) {
        const sig = await signChainbornBehaviourMessage({ type: config.NETWORK_NAME, rpc: config.RPC_ENDPOINT, account: wallet })
        await login(sig) // Sets cookie
        await executeBattleTurn(payload)
        await _useBattleItem({
          contractAddress: config.CHAINBORN_GAME_CONTROLLER_ADDRESS,
          bid: battle.bid,
          item,
          user_hero: attacker,
          target_hero: attacker  
        }) 
        await notify(config.NETWORK_NAME, attacker.owner, defender.owner, item?.store?.item_category, `${attacker.name} used an item!`, `/battles/${battle.bid}`)
      } else {
        console.error(e)
        setAlert({ title: 'Unable to use item', description: e.message })
      }
    }
    setLoading(false)
  }

  const potionBeltItems = Array.from(new Array(POTIONBELT_MAX_POTIONS)).map((_,i) => (
    <Tip key={i} text={beltItems[i] ? beltItems[i]?.store?.item?.name : 'Potionbelt'}>
      <BeltSlot onClick={(e) => handleUsePotion(e, beltItems[i])}>
        { beltItems[i] &&
          <PotionBeltItemImage src={beltItems[i].store.displayUri}  />
        }
        { !beltItems[i] &&
          <PotionIcon color='brand' size="12" />
        }
      </BeltSlot>
    </Tip>
  ))
  
  return (
    <PotionBeltWrapper>
      {potionBeltItems}
    </PotionBeltWrapper>
  )
}

const PotionBeltWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const BeltSlot = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-brand-middle);
  background-color: var(--color-brand-dark);
  box-shadow: inset 0 0px 10px black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 620px) {
    width: 20px;
    height: 20px;
  }
`

const PotionBeltItemImage = styled.img`
  z-index: 0;
  width: 28px;
  height: 28px;
  @media (max-width: 620px) {
    width: 18px;
    height: 18px;
  }
`
