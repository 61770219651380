import styled from 'styled-components'
import { Spinner as GSpinner } from 'grommet'

const Spinner = styled(GSpinner)`
  margin: auto;
  border-left-color: #009886;
  border-right-color: #009886;
  border-bottom-color: #009886;
  ${props => props.centered && `
    position:absolute;
    top: calc(50% - 48px);
    left: calc(50% - 48px);
  `}
`;

export default Spinner