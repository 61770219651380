import { atom, selector, selectorFamily, useRecoilState } from 'recoil'
import { 
  getFBToken,
  getSettings,
  getTezIDProfile
} from './api'
import { authWithFirebase } from './firebase'

export const walletState = atom({
  key: 'walletState',
  default: null
})

const signatureAtom = atom({
  key: 'signatureAtom',
  default: selector({
    key: 'signatureAtom/default',
    get: async ({ get }) => {
      let sig = localStorage.getItem('chainborn_signature')
      return JSON.parse(sig || '{}')
    }
  })
})

const firebaseTokenStateAtom = atom({
  key: 'firebaseTokenStateAtom',
  default: selector({
    key: 'firebaseTokenStateAtom/default',
    get: async ({ get }) => {
      const token = localStorage.getItem('chainborn_firebase_token')
      try {
        const wallet = get(walletState)
        await authWithFirebase(token, wallet)
      } catch(e) {
        try {
          const sig = Object.assign({}, get(signatureAtom))
          const wallet = get(walletState)
          if (!sig?.pk && !wallet?.publicKey) return ""
          if (!sig?.pk && wallet?.publicKey) sig.pk = wallet.publicKey
          const newToken = await getFBToken(sig)
          await authWithFirebase(newToken, wallet)
          localStorage.setItem('chainborn_firebase_token', newToken)
          return newToken
        } catch(e) {
          console.error(e)
          return ""
        }
      }
      return token
    }
  })
})

export const tezidProfileAtom = atom({
  key: 'tezidProfileAtom',
  default: selector({
    key: 'tezidProfileAtom/default',
    get: async ({ get }) => {
      try {
        const wallet = get(walletState)
        const profile = await getTezIDProfile(wallet?.address)
        return profile
      } catch(e) {
        console.error(e)
      }
      return null 
    }
  })
})

export const settingsAtom = atom({
  key: 'settingsAtom',
  default: selector({
    key: 'settingsAtom/default',
    get: async ({ get }) => {
      try {
        const wallet = get(walletState)
        const settings = await getSettings(wallet?.address)
        return settings
      } catch(e) {
        console.error(e)
      }
      return null 
    }
  })
})

export const signatureState = selector({
  key: 'signatureState',
  get: async ({get}) => {
    return get(signatureAtom)
  },
  set: ({set}, newVal) => {
    localStorage.setItem('chainborn_signature', JSON.stringify(newVal))
    set(signatureAtom, newVal)
  }
})

export const firebaseTokenState = selector({
  key: 'firebaseTokenState',
  get: async ({get}) => {
    return get(firebaseTokenStateAtom)
  },
  set: ({set}, newVal) => {
    localStorage.setItem('chainborn_firebase_token', newVal)
    set(firebaseTokenStateAtom, newVal)
  }
})

/*
* Status messages 
* Shape: { 
    title: String, 
    description: String,
    severity: String ('error' | 'warning' | 'ok')
  }
*/
const alertAtom = atom({
  key: 'alertState',
  default: null
})

export function useAlert() {
  const [ alert, setAlert ] = useRecoilState(alertAtom)
  return [ alert, (props) => {
    if (props === null) {
      setAlert(null)
    }
    else {
      const { title, description, severity='error' } = props
      setAlert({ title, description, severity })
    }
  }]
}


/*
* Battle Spinners 
*/

export const acceptLoadingAtom = atom({
  key: 'acceptLoadingAtom',
  default: {}
})

export const cancelLoadingAtom = atom({
  key: 'cancelLoadingAtom',
  default: {}
})

export const attackLoadingAtom = atom({
  key: 'attackLoadingAtom',
  default: {}
})

export const resolveLoadingAtom = atom({
  key: 'resolveLoadingAtom',
  default: {}
})

export const acceptLoadingState = selectorFamily({
  key: 'acceptLoadingState',
  get: (bid) => ({get}) => {
    return get(acceptLoadingAtom)[bid]
  },
  set: (bid) => ({get, set}, loading) => {
    const newValue = { ...get(acceptLoadingAtom) }
    newValue[bid] = loading
    set(acceptLoadingAtom, newValue)
  }
})

export const cancelLoadingState = selectorFamily({
  key: 'cancelLoadingState',
  get: (bid) => ({get}) => {
    return get(cancelLoadingAtom)[bid]
  },
  set: (bid) => ({get, set}, loading) => {
    const newValue = { ...get(cancelLoadingAtom) }
    newValue[bid] = loading
    set(cancelLoadingAtom, newValue)
  }
})

export const attackLoadingState = selectorFamily({
  key: 'attackLoadingState',
  get: (bid) => ({get}) => {
    return get(attackLoadingAtom)[bid]
  },
  set: (bid) => ({get, set}, loading) => {
    const newValue = { ...get(attackLoadingAtom) }
    newValue[bid] = loading
    set(attackLoadingAtom, newValue)
  }
})

export const resolveLoadingState = selectorFamily({
  key: 'resolveLoadingState',
  get: (bid) => ({get}) => {
    return get(resolveLoadingAtom)[bid]
  },
  set: (bid) => ({get, set}, loading) => {
    const newValue = { ...get(resolveLoadingAtom) }
    newValue[bid] = loading
    set(resolveLoadingAtom, newValue)
  }
})
