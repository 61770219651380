import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import formatNumber from 'format-number'
import { create as createIdentityImage } from 'identity-img'
import queryString from 'query-string'
import { uid } from 'uid'
import debounce from 'debounce'
import { validateAddress } from '@taquito/utils'
import { fetchAddressFromTezosDomain, fetchTezosDomainFromAddress } from 'shared/wallet'
import { firestore } from 'shared/firebase' 
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import {
  HERO_IMG_SIZE,
  HERO_IMAGE_RATIO,
  HERO_IMAGE_TRANSFORM,
  DEFAULT_HERO_IMG_SIZE,
  COLLECTION_IMAGE_SIZE,
  COLLECTION_IMAGE_RATIO,
  COLLECTION_IMAGE_TRANSFORMS,
} from 'shared/constants'
import {
  getTezIDProfile as getTezIDProfileAPI
} from './api'
import { 
  TIME_FORMAT,
  DATE_FORMAT,
  IPFS_BASE_URI,
} from './constants'

export function getHeroImgSize(hero, size) {
  if (!hero) return
  let hid = getItemID(hero)
  return size * (COLLECTION_IMAGE_SIZE[hero?.token_address] || HERO_IMG_SIZE[hid] || DEFAULT_HERO_IMG_SIZE)
}

export function getHeroIconSize(size) {
  return size / 8 // TOOD: move to contants if we ever use this again
}

export function getHeroImgRatio(hero) {
  if (!hero) return
  let hid = getItemID(hero)
  return COLLECTION_IMAGE_RATIO[hero?.token_address] || HERO_IMAGE_RATIO[hid]
}

export function getHeroImgTransforms(hero, size) {
  if (!hero) return
  let hid = getItemID(hero)
  return COLLECTION_IMAGE_TRANSFORMS[hero?.token_address] || HERO_IMAGE_TRANSFORM[hid]
}

export function getItemID(item) { return `${item?.token_id}+${item?.token_address}` }

export function formatLegs(legs) {
  return formatNumber({ round: 1 })(legs)
}

export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function decapitalizeFirstLetter(word) {
  return word.charAt(0).toLowerCase() + word.slice(1)
}

export function joinItemsWithTokenEditions(items) {
  const m = items.reduce((map, _item) => {
    let item = Object.assign({}, _item)
    let id = getItemID(item)
    if (map[id]) {
      map[id].token_editions += 1
    }
    else {
      item.token_editions = item?.token_editions || 1
      map[id] = item
    }
    return map
  }, {})
  return Object.values(m)
}

export function getCardSizeFromPageWidth(width) {
  let cardSize = 350
  if (width < 900 && width >= 768) cardSize = 285 
  if (width < 768) cardSize = 240 
  if (width < 620) {
    cardSize = 180
  } 
  return cardSize
}

export function openLink(target) {
  window.open(target, '_blank'); 
}

export async function notify(network, sender, receiver, type, text, link) {
  const notificationsRef = collection(firestore, `/${network}/notifications/${receiver}`);
  await addDoc(notificationsRef, {
    uid: uid(8),
    read: false,
    type: type,
    text: text,
    link: link,
    createdAt: new Date(), // TODO: Get server timestamp 
    sender
  })
}

export async function markNotificationRead(network, address, fid) {
  if (!network || !address || !fid) return
  const notificationsRef = doc(firestore, `/${network}/notifications/${address}/${fid}`);
  await updateDoc(notificationsRef, {
    read: true
  })
}

const imgCache = {}
export function getIdentityImage(address) {
  const idimg = new Image()
  if (!imgCache[address]) {
    imgCache[address] = createIdentityImage(address) 
  }
  idimg.src = imgCache[address]
  return idimg
}

const profileCache = {}
export async function getTezIDProfile(address) {
  if (!profileCache[address]) {
    profileCache[address] = await getTezIDProfileAPI(address) 
  }
  return profileCache[address] 
}

const playerImageCache = {}
export async function getPlayerImage(address) {
  if (!playerImageCache[address]) {
    try {
      const profile = await getTezIDProfile(address)
      playerImageCache[address] = profile.avatar ? getIpfsLink(profile.avatar) : getIdentityImage(address).src
    } catch(e) {
      playerImageCache[address] = '' 
    }
  }
  return playerImageCache[address]
}

const playerNameCache = {}
export async function getPlayerName(address) {
  if (!playerNameCache[address]) {
    try {
      let domain = ''
      let profile = {}
      let username = address
      try {
        domain = await fetchTezosDomainFromAddress(address)
        username = domain || address
      } catch(e) {
        // Unable to fetch domain 
      }
      try {
        profile = await getTezIDProfile(address)
        username = profile?.name || address
      } catch(e) {
        // Unable to fetch profile 
      }
      playerNameCache[address] = username.length > 13 ? truncateAddress(username) : username
    } catch(e) {
      //console.log(e)
      playerNameCache[address] = address ? truncateAddress(address) : ''
    }
  }
  return playerNameCache[address]
}

export function formatDate(date) {
  try {
    return format(new Date(date), DATE_FORMAT) 
  } catch(e) {
    // console.error(e)
  }
  return null
}

export function formatTime(date) {
  try {
    return format(new Date(date), TIME_FORMAT) 
  } catch(e) {
    // console.error(e)
  }
  return null
}

export function getBetterCallDevURI(network) {
  return `https://better-call.dev/${network}`
}

export function getIpfsLink(ipfsPath) {
  return `${IPFS_BASE_URI}/${ipfsPath?.split('ipfs://')[1]}`
}

export async function sleep(seconds) {
  await new Promise(resolve => setTimeout(resolve, seconds*1000))
}

export function isEqualHero(a, b) {
  if (!a || !b) {
    return false
  }

  const addressA = 'token_address' in a ? 'token_address' : 'address'
  const addressB = 'token_address' in b ? 'token_address' : 'address'
  const idA = 'token_id' in a ? 'token_id' : 'nat'
  const idB = 'token_id' in b ? 'token_id' : 'nat'

  return (
    a[addressA] === b[addressB] && 
    String(a[idA]) === String(b[idB]) &&
    a[addressA] !== undefined &&
    b[addressB] !== undefined &&
    a[idA] !== undefined &&
    b[idB] !== undefined
  )
}

export function statusToQuery(status) {
  let query
  switch (status) {
    case 'challenge':
      query = `{ start_time: { _is_null: true } }`
      break
    case 'ongoing':
      query = `{ start_time: { _is_null: false }, finish_time: { _is_null: true }, resolved: { _eq: false } }`
      break
    case 'finished':
      query = `{ start_time: { _is_null: false }, finish_time: { _is_null: false }, resolved: { _eq: false } }`
      break
    case 'resolved':
      query = `{ start_time: { _is_null: false }, resolved: { _eq: true } }`
      break
    case 'started':
      query = `{ start_time: { _is_null: false } }`
      break
    case 'unresolved':
      query = `{ resolved: { _eq: false } }`
      break
    default:
      query = ``
      break
  }
  return query
}

export function lootToQuery(lootString) {
  let query = ''
  if (lootString) {
    const [ min, max ] = lootString.split('-')
    query = `
      ${min ? `{loot: { _gte: ${min} }},` : ''}
      ${max ? `{loot: { _lte: ${max} }},` : ''}
    `
  }
  return query
}

/*
* Like useState, but keeps the state in URL Search parameters
*/
export function useUrlState() {
  const query = queryString.parse(window.location.search)

  const setQuery = useCallback(queryObject => {
    const queryObjectNoNulls = Object.keys(queryObject).reduce((acc, key) => {
      if (queryObject[key] !== null) {
        acc[key] = queryObject[key]
      }
      return acc
    }, {})
    const path = window.location.pathname
    const search = queryString.stringify(queryObjectNoNulls)
    const newUrl = search ? `${path}?${search}` : path
    window.history.pushState({}, '', newUrl)
  }, [])

  return [ query, setQuery ]
}

/*
* Keeps the state in the localStorage
*/
export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem(key);
      const initial = saved !== null ? JSON.parse(saved) : defaultValue;
      return initial;
    }
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

/*
* Get the screen size on resizing
*/
export function useResponsive(debounceDefault=200) {
  const [ width, setWidth ] = useState(window.innerWidth)
  useEffect(() => {
    const onResize = debounce(event => {
      setWidth(window.innerWidth)
    }, debounceDefault)
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [debounceDefault])
  return { width }
}

/*
* Util hook to fetch more data from a GraphQL query
*/
export function useFetchMore(data, dataName, fetchMore) {

  const [ hasMore, setHasMore ] = useState(false)

  const onFetchMore = useCallback(() => {
    fetchMore({
      variables: {
        offset: data.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult[dataName].length > 0) {
          return {
            [dataName]: previousResult[dataName].concat(fetchMoreResult[dataName])
          }
        } 
      },
    })
  }, [data, dataName, fetchMore])

  useEffect(() => {
    const checkIfMore = () => {
      fetchMore({
        variables: {
          offset: data.length
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setHasMore(fetchMoreResult[dataName].length > 0)
        },
      })
    }
    checkIfMore()
  }, [data, dataName, fetchMore])

  return { onFetchMore, hasMore }
}

/*
* Returns a shorter address
*/
export function truncateAddress(addr) {
  return `${addr.substring(0,7)}..${addr.substring(addr.length-4,addr.length)}`
}

/*
* tzkt.io URI 
*/
export function getTzktURI(config) {
  const prefix = config?.NETWORK_NAME !== 'mainnet' ? `${config?.NETWORK_NAME}.` : ''
  return `https://${prefix}tzkt.io`
}

/*
* Unlocks state when a condition is met
*/
export function useUnlockState(conditionStart, conditionFinish, callback, deps) {
  useEffect(() => {
    if (conditionStart()) {
      if (conditionFinish()) {
        callback()
      } 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}

/*
* Changes the document title
*/
export function useDocumentTitle(title) {
  useEffect(() => {
    if (title) {
      document.title = title
    }
    return () => {
      document.title = 'ChainBorn'
    }
  }, [title])
}

/*
* Returns the tz1 address+loading from a .tez domain 
*/
export function useDomainToAddress(domainOrAddress, debounceDelay=500) {
  const [ address, setAddress ] = useState(domainOrAddress)
  const [ loading, setLoading ] = useState(false)
  
  useEffect(() => {
    const getAddress = async () => {
      try {
        if (validateAddress(domainOrAddress)) {
          return domainOrAddress
        }
        else {
          const domainMaybe = domainOrAddress.endsWith('.tez') ? domainOrAddress : `${domainOrAddress}.tez`
          const address = await fetchAddressFromTezosDomain(domainMaybe)
          if (validateAddress(address)) {
            return address
          }
          else {
            return ''
          }
        }
      }
      catch (e) {
        return ''
      }
    }

    let timeoutId
    if (domainOrAddress !== undefined) {
      setLoading(true)
      timeoutId = setTimeout(async () => {
        const address = await getAddress()
        if (address !== undefined) {
          setAddress(address)
        }
        setLoading(false)
      }, debounceDelay)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [domainOrAddress, debounceDelay])

  return [ loading, address ]
}

export function isTouchDevice() {
  return (
    ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)
  )
}

export function hideScrollbarCSS() {
  return `
    /* Hide scrollbar in FF & IE*/
    scrollbar-width: none; 
    -ms-overflow-style: none;
    /* Hide scrollbar in Chrome, Safari, Edge */
    &::-webkit-scrollbar {
      display: none;
    }
  `
}

export const isElementOverflow = element => {
  if (!element) return false
  const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element
  return scrollHeight > clientHeight || scrollWidth > clientWidth
}
