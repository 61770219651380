import { Helmet } from 'react-helmet'
import { PRERENDER_BASE_URI } from 'shared/constants'

export default function Helm({ title, description, uri }) {
  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@chainborngame" />
      <meta name="twitter:creator" content="@chainborngame" />
      <meta property="og:title" content={`${title}`} />
      <meta property="og:image" content={`${PRERENDER_BASE_URI}${uri}.png`} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="660" />
      <meta property="og:description" content={`${description}`} />
   </Helmet>
  )
}
