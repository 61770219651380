import { 
  gql,
  split, 
  HttpLink, 
  ApolloClient, 
  InMemoryCache, 
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { useQuery } from '@apollo/client'
import { Config, StoreItem, MinistryVictorLoot, InventoryItem, PlayerData, PlayerStats, LeagueStats, Collection, Hero, PossibleHero, Battle, Tournament, Legs } from './models'
import { isEqualHero, statusToQuery, lootToQuery, useDomainToAddress, decapitalizeFirstLetter } from './utils'
import { HASURA_HOST, HEROES_TO_IGNORE } from './constants'

const POLL_INTERVAL = 5000

/** INIT **/

const httpLink = new HttpLink({
  uri: `https://${HASURA_HOST}/v1/graphql`
})

const wsLink = new WebSocketLink({
  uri: `wss://${HASURA_HOST}/v1/graphql`,
  options: {
    reconnect: true
  }
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
)

let apolloClient;
export function getApolloClient() {
  if (!apolloClient) apolloClient = new ApolloClient({
    uri: `https://${HASURA_HOST}/v1/graphql`,
    link: splitLink,
    cache: new InMemoryCache()
  })
  return apolloClient
}


/** HOOKS **/

/*
* Fetch the config of the game
*/
export function useGetConfig() {
  const { loading, data, error } = useQuery(gql`
    query getConfig {
      config {
        config
        network
        storage
        badges
        items
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
  })

  return {
    loading,
    error,
    config: loading ? null : new Config(data)
  }
}

/*
* Fetch Ministry Victory Loot
*/
export function useGetMinistryVictorLoot({ bid }) {
  const { loading, data, error } = useQuery(gql`
    query getMinistryVictorLoot($bid: String) {
      ministry_victor_loot (
        where: {
          bid: { _eq: $bid }
        }
      ) {
        bid
        victor
        reward
        reward_amount
        operation_hash
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { bid },
    skip: !bid
  })

  const loot = (loading || !data?.ministry_victor_loot[0]) ? null : new MinistryVictorLoot(data.ministry_victor_loot[0])

  return {
    loading,
    error,
    loot 
  }
}

/*
* Fetch players
*/
export function useGetPlayers({ 
    sort_by='num_battles', 
    sort_direction='desc', 
    offset=0, 
    limit=20 
  }) {

  const { loading, data, error, fetchMore } = useQuery(gql`
    query getPlayerStats(
      $order_by: playerstats_order_by!,
      $offset: Int,
      $limit: Int
    ) {
      playerstats (
        where: {
          experience: { _is_null: false }
        },
        order_by: [$order_by],
        offset: $offset,
        limit: $limit
      ) {
        address,
        experience,
        num_heroes,
        num_battles
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: {
      order_by: { [sort_by]: sort_direction },
      offset,
      limit 
    }
  })

  const players = 
    (data?.playerstats || [])
      .map(player => new PlayerStats(player))

  return {
    loading,
    error,
    fetchMore,
    players
  }
}

/*
* Fetch league state 
*/
export function useGetLeagueStats({ 
    year
  }) {

  const { loading, data, error, fetchMore } = useQuery(gql`
    query getLeagueStats(
      $year: float8
    ) {
      leaguestats (
        where: {
          year : { _eq: $year }
        },
      ) {
        bid,
        victor,
        loser,
        year,
        tournament_battle
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: {
      year
    }
  })

  const stats = 
    (data?.leaguestats || [])
      .map(stat => new LeagueStats(stat))

  return {
    loading,
    error,
    fetchMore,
    stats
  }
}

/*
* Fetch playerdata for address 
*/
export function useGetPlayerData({ address }) {
  const { loading, data, error } = useQuery(gql`
    query getPlayerData($address: String) {
      players (
        where: {
          address: { _eq: $address }
        }
      ) {
        playerdata,
        blacklisted,
        blacklisting
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { address },
    skip: !address
  })

  const playerData = (loading || !data?.players[0]) ? null : new PlayerData(data.players[0])

  return {
    loading,
    error,
    playerData
  }
}

/*
* Fetch all tournaments 
*/
export function useGetTournaments({
    limit=5,
    offset=0
  }) {
  const { loading, data, error, fetchMore } = useQuery(gql`
    query getTournaments(
      $offset: Int, 
      $limit: Int,
    ) {
      tournaments (
        order_by: { created_at: desc },
        where: {
          ignore: { _eq: false },
        },
        offset: $offset,
        limit: $limit
      ) {
        address,
        tournament
        synced_at
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: {
      offset,
      limit
    }
  })

  const tournaments = 
    data?.tournaments.map(item => new Tournament(item)) || []

  return {
    loading,
    error,
    fetchMore,
    tournaments
  }
}

/*
* Fetch a tournament
*/
export function useGetTournament({ address }) {
  const { loading, data, error } = useQuery(gql`
    query getTournaments($address: String) {
      tournaments (
        order_by: { synced_at: desc },
        where: {
          ignore: { _eq: false },
          address: { _eq: $address}
        }
      ) {
        address,
        tournament
        synced_at
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { address }
  })

  const tournament = 
    data?.tournaments.map(item => new Tournament(item))[0] || []

  return {
    loading,
    error,
    tournament
  }
}

/*
* Get LEGs for address
*/
export function useGetLegsForAddress({ address }) {
  const { loading, data, error } = useQuery(gql`
    query getLegsForAddress($address: String) {
      legs (
        where: {
          address: { _eq: $address}
        }
      ) {
        address,
        balance, 
        rewards_claimed
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { address }
  })

  const legs = 
    data?.legs.map(item => new Legs(item))[0] || {}

  return {
    loading,
    error,
    legs
  }
}

/*
* Fetch all store items 
*/
export function useGetStoreItems({
  name,
  markets,
  category,
  price_max,
  price_min
}={}) {

  // NOTE: Lage til for filters her, men kan fra Store ikke sende
  // disse inn da vi trenger alle storeItems for å kunne mappe mot marketplace listings.
  // Derfor gjør vi heller filtreringe for Store frontned.
  if (typeof category === 'string') category = [category]
  if (category) category = category.map(c => decapitalizeFirstLetter(c))
  if (typeof markets === 'string') markets = [markets]
  if (markets) {
    markets = markets.map(m => {
      if (m === 'Store') return 0
      if (m === 'Marketplace') return 1
      return 0
    })
    if (markets.indexOf(1) >= 0) markets.push(2)
  }

  const { loading, data, error } = useQuery(gql`
    query getStoreItems(
      $name: String
      $markets: jsonb
      $category: jsonb
      $price_max: String
      $price_min: String
    ) {
      store (
        where: {
          _and: [
            ${ name ? `{ mid: { _ilike: "%${name}%" } },` : ''}
            ${ price_max ? `{ item_price: { _lte: "${price_max}" } },` : ''}
            ${ price_min ? `{ item_price: { _gte: "${price_min}" } },` : ''}
            ${ markets ? `{ item_market: { _in: ${JSON.stringify(markets)} } },` : ''}
            ${ category ? `{ item_category: { _in: ${JSON.stringify(category)} } },` : ''}
          ]
        }
        order_by: [
          { item_category: asc },
          { token_id: desc }
        ],
      ) {
        mid 
        token_id
        token_address
        amount_available
        item
        item_category
        item_price
        item_market
        metadata
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: {
      name,
      markets,
      category,
      price_max,
      price_min
    }
  })

  const items = 
    data?.store.map(item => new StoreItem(item)) || []

  return {
    loading,
    error,
    items 
  }
}

/*
* Fetch all unequipped player items 
*/
export function useGetPlayerItems({ address }) {
  const { loading, data, error } = useQuery(gql`
    query getStoreItems($address: String) {
      items (
        order_by: { token_id: desc },
        where: {
          token_owner: { _eq: $address },
          token_editions: { _gt: 0 }
        }
      ) {
        token_id
        token_address
        token_owner
        token_editions
        token_metadata
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { address }
  })

  const items = 
    data?.items.map(item => new InventoryItem(item)) || []

  return {
    loading,
    error,
    items 
  }
}

/*
* Fetch all collections
*/
export function useGetCollections() {
  const { loading, data, error } = useQuery(gql`
    query getCollections {
      collections (
        order_by: { sort_order: asc }
      ) {
        name
        lore
        link
        crest
        banner
        address
      }
    }
  `)

  const collections = 
    data?.collections.map(collection => new Collection(collection)) || []

  return {
    loading,
    error,
    collections
  }
}

/*
* Fetch 1 collection
*/
export function useGetCollection({ address }) {
  const { loading, data, error } = useQuery(gql`
    query getCollections($address: String) {
      collections(
        where: {
          address: { _eq: $address }
        }
      ) {
        address
        name
        crest
        lore
        link
      }
    }
  `, {
    variables: { address }
  })

  const collection = data?.collections?.[0]
    ? new Collection(data?.collections?.[0]) 
    : null

  return {
    loading,
    error,
    collection
  }
}
/*
* Fetch possible heroes of a specific owner to be summoned
*/
export function useGetPossibleHeroes({ owner='' }) {
  const { loading, data, error } = useQuery(gql`
    query getPossibleHeroes($owner: String) {
      heroes(
        where: {
          hero: { _is_null: true }
          token_owner: { _eq: $owner }
        },
        order_by: [
          { token_address: asc },
          { token_id: asc }
        ]
      ) {
        token_owner
        token_metadata
        token_address
        token_id
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL,
    variables: { owner }
  })

  const heroes = data?.heroes || []

  return {
    loading,
    error,
    heroes: heroes.map(hero => new PossibleHero(hero))
  }
}

/*
* Fetch heroes
* 
* @param {String} owner: address or .tez domain 
* @param {String} not_owner: address or .tez domain 
* @param {String} name: hero name
* @param {String} token_address
* @param {Boolean} suited. Pass "null" for both suited/unsuited
* @param {String} sort_by
* @param {String} sort_direction
* @param {Number} offset
* @param {Number} limit
*/
export function useGetHeroes({ 
  owner, 
  not_owner, 
  name, 
  token_address, 
  suited=true,
  sort_by='summoned_at', 
  sort_direction='desc', 
  offset=0, 
  limit=25,
  xp_min=0,
  xp_max=500
}={}) {

  const { config } = useGetConfig()
  const [ loadingOwner, ownerAddress ] = useDomainToAddress(owner)
  const [ loadingNotOwner, notOwnerAddress ] = useDomainToAddress(not_owner)

  const { 
    loading, 
    data, 
    error,
    fetchMore
  } = useQuery(gql`
    query getHeroes(
      $owner: String, 
      $not_owner: String, 
      $name: String, 
      $token_address: String, 
      $order_by: heroes_order_by!, 
      $offset: Int, 
      $limit: Int,
      $xp_max: Int,
      $xp_min: Int
      ) {
      heroes(
        where: {
          _and: [
            { hero: { _is_null: false } },
            ${ token_address ? '{ token_address: { _eq: $token_address } },' : ''}
            ${ name ? `{ name: { _ilike: "%${name}%" } },` : ''}
            ${ ownerAddress ? `
              { _or : [
                { hero_owner: { _eq: $owner } },
                { token_owner: { _eq: $owner } }
              ]},
            `: ''}
            ${ notOwnerAddress ? `
              { hero_owner: { _neq: $not_owner } },
              { token_owner: { _neq: $not_owner } },
            ` : ''}
            ${ suited === null
                ? ``
                : suited ? `
                  { token_owner: { _eq: ${config?.CHAINBORN_DATASTORE} }}
                  ` : `
                  { token_owner: { _neq: ${config?.CHAINBORN_DATASTORE} }}`
              }
            { experience_total : { _gte: $xp_min } },
            { experience_total : { _lte: $xp_max } }
          ]
        },
        order_by: [$order_by, {token_address: desc}, {token_id: desc}],
        offset: $offset,
        limit: $limit
      ) {
        hero
        hero_owner
        token_owner
        token_address
        token_id
        token_metadata
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL,
    variables: { 
      owner: ownerAddress, 
      not_owner: notOwnerAddress, 
      name, 
      token_address, 
      order_by: { [sort_by]: sort_direction }, 
      offset, 
      limit,
      xp_max,
      xp_min
      // skip: loadingOwner || loadingNotOwner
    },
  })

  // Query data for badges
  // 
  const leaderboard = {
    wins: useGetHeroesMostWins()?.heroes,
    xp: useGetHeroesMostExperience()?.heroes,
    streak: useGetHeroesMostWinStreaks()?.heroes
  }

  const heroes = (data?.heroes || [])
    .map(heroServer => new Hero(heroServer, leaderboard))
    .filter(hero => HEROES_TO_IGNORE.indexOf(hero.id) < 0)
    .filter(hero => ownerAddress 
      ? hero.owner === ownerAddress // Required to filter out Heroes I have unsuited and transferred
      : true
    ) 

  return { 
    loading: loading || loadingOwner || loadingNotOwner,
    error,
    fetchMore,
    heroes
  }
}

/*
* Fetch a specific hero
*/
export function useGetHero({ address, id }) {
  // Get Hero
  // 
  const { 
    loading, 
    data, 
    error
  } = useQuery(gql`
    query getHero($address: String, $id: Int) {
      heroes(where: {
        token_address: { _eq: $address }
        token_id: { _eq: $id }
      }) {
        hero,
        wins,
        losses,
        token_id,
        hero_owner,
        token_owner,
        token_address,
        token_metadata,
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL,
    variables: { address, id },
    skip: !address || !id
  })
  const heroServer = data?.heroes?.[0]

  // Query data for badges
  const leaderboard = {
    wins: useGetHeroesMostWins()?.heroes,
    xp: useGetHeroesMostExperience()?.heroes,
    streak: useGetHeroesMostWinStreaks()?.heroes
  }

  let hero
  if (!loading && heroServer) {
    hero = new Hero(heroServer, leaderboard)
  }

  return {
    loading,
    error,
    hero
  }
}

export function useGetHeroByName({ name }) {
  // Get Hero
  // 
  const { 
    loading, 
    data, 
    error
  } = useQuery(gql`
    query GetHeroByName($name: String = "") {
      heroes(where: {_and: {name: {_is_null: false}}, name: {_eq: $name}}) {
        hero,
        wins,
        losses,
        token_id,
        hero_owner,
        token_owner,
        token_address,
        token_metadata,
      }
    }
  `, { 
    variables: { name },
    skip: !name
  })

  const heroServer = data?.heroes?.[0]

  // Query data for badges
  const leaderboard = {
    wins: useGetHeroesMostWins()?.heroes,
    xp: useGetHeroesMostExperience()?.heroes,
    streak: useGetHeroesMostWinStreaks()?.heroes
  }

  let hero
  if (!loading && heroServer) {
    hero = new Hero(heroServer, leaderboard)
  }

  return {
    loading,
    error,
    hero
  }
}

/*
* Fetch Top 3 heroes with most wins
*/
export function useGetHeroesMostWins() {
  const { 
    loading, 
    data, 
    error,
  } = useQuery(gql`
    query getHeroesMostWins {
      heroes(
        where: {
          _and: [
            { hero: { _is_null: false } }
          ]
        },
        order_by: [{ wins: desc }, { summoned_at: desc }],
        limit: 3
      ) {
        token_address
        token_id
        wins
        summoned_at
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL
  })

  const heroes = (data?.heroes || [])

  return { 
    loading,
    error,
    heroes
  }
}

/*
* Fetch Top 3 heroes with most experience
*/
export function useGetHeroesMostExperience() {
  const { 
    loading, 
    data, 
    error,
  } = useQuery(gql`
    query getHeroesMostExperience {
      heroes(
        where: {
          _and: [
            { hero: { _is_null: false } }
          ]
        },
        order_by: [{ experience_total: desc }, { summoned_at: desc }],
        limit: 3
      ) {
        token_address
        token_id
        experience_total
        summoned_at
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL
  })

  const heroes = (data?.heroes || [])
    .map(hero => ({ token_address: hero.token_address, token_id: hero.token_id, xp: hero.experience_total }))

  return { 
    loading,
    error,
    heroes
  }
}

/*
* Fetch Top 3 heroes with most winning streaks
*/
export function useGetHeroesMostWinStreaks() {
  const { 
    loading, 
    data, 
    error,
  } = useQuery(gql`
    query getHeroesMostWinStreaks {
      streaks(
        order_by: [{ streak: desc }, { time_began: desc }],
        limit: 3
      ) {
        player,
        streak
      }
    }
  `, { 
    pollInterval: POLL_INTERVAL
  })

  const heroes = (data?.streaks || [])
    .map(streak => ({ ...streak.player, streak: streak.streak }))
    .map(hero => ({ token_address: hero.address, token_id: hero.nat, streak: hero.streak }))

  return { 
    loading,
    error,
    heroes
  }
}

/*
* Fetch battles
* 
* @param {String} owner: address or .tez domain 
* @param {String} not_owner: address or .tez domain 
* @param {(challenge|ongoing|finished|resolved|started)} status
* @param {String} token_address
* @param {Number} token_id
* @param {Number} loot
* @param {String} sort_by
* @param {String} sort_direction
* @param {Number} offset
* @param {Number} limit
*/
export function useGetBattles({ 
  owner, 
  not_owner, 
  status, 
  token_address='', 
  token_id, 
  loot, 
  sort_by='challenge_time', 
  sort_direction='desc', 
  offset=0, 
  limit=10 
}) {

  const [ loadingOwner, ownerAddress ] = useDomainToAddress(owner)
  const [ loadingNotOwner, notOwnerAddress ] = useDomainToAddress(not_owner)

  const { loading, data, error, fetchMore } = useQuery(gql`
    query getMyBattles(
      $owner: String, 
      $not_owner: String,
      $token_address: String,
      $token_id: String,
      $order_by: battles_order_by!,
      $offset: Int,
      $limit: Int
      ) {
      battles(
        where: {
          _and : [
            {cancelled: { _eq: false }},
            ${ownerAddress ? `
              {_or: [ 
                {challenger_owner: { _eq: $owner }},
                {challenged_owner: { _eq: $owner }},
              ]},
            ` : ''}
            ${notOwnerAddress ? `
              {challenger_owner: { _neq: $not_owner }},
              {challenged_owner: { _neq: $not_owner }},
            ` : ''}
            ${statusToQuery(status)},
            ${token_address ? `
              {_or: [
                {challenger_token_address: { _eq: $token_address } }, 
                {challenged_token_address: { _eq: $token_address } }
              ]},
            ` : ''}
            ${token_id ? `
              {_or: [
                {challenger_token_id: { _eq: $token_id } }, 
                {challenged_token_id: { _eq: $token_id } }
              ]},
            ` : ''}
            ${lootToQuery(loot)},
          ]
        },
        order_by: [$order_by],
        offset: $offset,
        limit: $limit
      ) {
        battle
        offchain_battle
        bid
        challenged_owner
        challenger_owner
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { 
      owner: ownerAddress, 
      not_owner: notOwnerAddress, 
      token_address, 
      token_id, 
      order_by: { [sort_by]: sort_direction }, 
      offset, 
      limit 
    },
  })

  const { config } = useGetConfig()

  const battles = (data?.battles || [])
    .map(battle => new Battle(battle, config))

  return { 
    loading: loading || loadingOwner || loadingNotOwner,
    error,
    fetchMore,
    battles
  }
}

export function useGetHeroBattles({ id, address }) {
  const { loading, data, error } = useQuery(gql`
    query getHeroBattles($token_id: String, $token_address: String) {
      battles(
        where: {
          _or: [
            { 
              _and: [
                { start_time: { _is_null: false } },
                { cancelled: { _eq: false } },
                { challenger_token_id: { _eq: $token_id } },
                { challenger_token_address: { _eq: $token_address } }
              ]
            },
            { 
              _and: [
                { start_time: { _is_null: false } },
                { cancelled: { _eq: false } },
                { challenged_token_id: { _eq: $token_id } },
                { challenged_token_address: { _eq: $token_address } }
              ]
            }
          ]
        },
        order_by: [
          { challenge_time: desc }
        ],
        limit: 5
      ) {
        battle
        bid
        challenged_owner
        challenger_owner
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { token_id: id, token_address: address },
  })

  const { config } = useGetConfig()

  const battles = data?.battles.map(battle => new Battle(battle, config))

  return { 
    loading, 
    error,
    battles
  }
}

export function useGetTournamentBattles({ bids }) {
  const { loading, data, error } = useQuery(gql`
    query getTournamentBattles($bids: [String!]) {
      battles(
        where: {
          bid: { _in : $bids }
        },
        order_by: [
          { challenge_time: desc }
        ],
        limit: 5
      ) {
        battle
        bid
        challenged_owner
        challenger_owner
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { bids: bids },
  })

  const { config } = useGetConfig()

  const battles = data?.battles.map(battle => new Battle(battle, config))

  return { 
    loading, 
    error,
    battles
  }
}

/*
* Fetch a specific Battle
*/
export function useGetBattle({ bid }) {
  // Get Battle
  // 
  const { 
    loading: loadingBattle, 
    error: errorBattle,
    data,  
  } = useQuery(gql`
    query getBattle($bid: String) {
      battles(where: {
        bid: { _eq: $bid }
      }) {
        bid
        battle
        offchain_battle
        challenged_owner
        challenged_token_id
        challenged_token_address
        challenger_owner
        challenger_token_id
        challenger_token_address
        cancelled
      }
    }
  `, {
    pollInterval: POLL_INTERVAL,
    variables: { bid },
  })
  const battle = data?.battles?.[0]?.battle
  const offchain_battle = data?.battles?.[0]?.offchain_battle

  const { config } = useGetConfig()

  // Get Heroes data and add them to the Battle
  // 
  const {
    loading: loadingChallenger,
    error: errorChallenger,
    hero: challenger
  } = useGetHero({ address: battle?.challenger.address, id: battle?.challenger.nat })

  const {
    loading: loadingChallenged,
    error: errorChallenged,
    hero: challenged
  } = useGetHero({ address: battle?.challenged.address, id: battle?.challenged.nat })

  const loading = loadingBattle || loadingChallenger || loadingChallenged
  const error = errorBattle || errorChallenger || errorChallenged

  let battleObject
  if (!loading && !error) {
    // Calculcate Heroes health
    const turns = offchain_battle?.turns
    if (challenger && challenged && turns?.length > 0) {
      challenged.damage = 0
      challenger.damage = 0
      for (const turn of turns) {
        if (turn.mods) {
          // V2 Battle
          let turn_damage = turn.action === 'attack' ? turn.mods.damage : 0
          let turn_healing = turn.mods.health || 0
          if (isEqualHero(turn.hero, challenger)) {
            challenged.damage += parseInt(turn_damage)
            challenger.damage -= parseInt(turn_healing)
          }
          if (isEqualHero(turn.hero, challenged)) {
            challenger.damage += parseInt(turn_damage)
            challenged.damage -= parseInt(turn_healing)
          }
        } else {
          // V1 Battle
          let turn_damage = turn.damage
          let turn_healing = 0
          if (turn_damage > 10000) {
            turn_damage = 0
            turn_healing = turn.damage - 10000
          }
          if (isEqualHero(turn.hero, challenger)) {
            challenged.damage += parseInt(turn_damage)
            challenger.damage -= parseInt(turn_healing)
          }
          if (isEqualHero(turn.hero, challenged)) {
            challenger.damage += parseInt(turn_damage)
            challenged.damage -= parseInt(turn_healing)
          }
        }
      }
    }

    // Find vitor/looser
    const victor = isEqualHero(challenger, battle?.victor) 
      ? challenger
      : isEqualHero(challenged, battle?.victor)
      ? challenged
      : null
    const looser = isEqualHero(challenger, battle?.looser) 
      ? challenger
      : isEqualHero(challenged, battle?.looser)
      ? challenged
      : null

    // Merge Heroes to the Battle
    const battleAllData = { 
      ...data?.battles?.[0], 
      battle: { 
        ...battle, 
        challenger, 
        challenged,
        victor,
        looser,
      }
    }

    battleObject = new Battle(battleAllData, config)
  }

  return { 
    loading, 
    error,
    battle: battleObject
  }
}

// export async function waitForIndexToUpdate(query, variables, validateFn, errorMessage='Data might be incorrect') {
//   const maxTries = 10
//   for (let i=0; i<maxTries; i++) {
//     const { data, loading } = await apolloClient.query({ query, variables })
//     if (validateFn({ data, loading })) {
//       return true
//     }
//     await sleep(1)
//   }
//   throw new Error({ title: errorMessage })
// }
