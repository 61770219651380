import styled from 'styled-components'
import { useGetHero } from 'shared/apollo'
import { BATTLE_MODE } from 'shared/constants'
import { getHeroImgTransforms } from 'shared/utils'
import Link from '../Link'
import Box from '../Box'
import { LootIcon, HeroIcon } from '..'
import frameImage from './frame.webp'

const sizeRatio = 0.5144965876

export default function BattleCard({ battle, indicateVictor=false, size=400, margin }) {
  const challenger_token_id = battle?.challenger?.token_address ? battle?.challenger?.token_id : battle?.challenger?.nat
  const challenger_token_address = battle?.challenger?.token_id ? battle?.challenger?.token_address : battle?.challenger?.address
  const challenged_token_id = battle?.challenged?.token_address ? battle?.challenged?.token_id : battle?.challenged?.nat
  const challenged_token_address = battle?.challenged?.token_id ? battle?.challenged?.token_address : battle?.challenged?.address
  const { hero: challenger } = useGetHero({ 
    id: challenger_token_id, 
    address: challenger_token_address
  })
  const { hero: challenged } = useGetHero({ 
    id: challenged_token_id, 
    address: challenged_token_address
  })

  const isChallengerVictor = battle.resolved && challenger?.owner === battle.victor_player
  const isChallengedVictor = battle.resolved && challenged?.owner === battle.victor_player
  const isChallengerLoser = battle.resolved && !isChallengerVictor && indicateVictor
  const isChallengedLoser = battle.resolved && !isChallengedVictor && indicateVictor

  const challenger_img_transforms = getHeroImgTransforms(challenger)
  const challenged_img_transforms = getHeroImgTransforms(challenged)

  return (
    <Link href={`/battles/${battle.bid}`}>
      <Card width={`${size}px`} height={`${size*sizeRatio}px`} margin={margin}>
        <FrameImage src={frameImage} />
        <FighterImageWrapperLeft>
          <ChallengerImage src={challenger?.thumbnailUri} loser={isChallengerLoser} transform={challenger_img_transforms} />
        </FighterImageWrapperLeft>
        <FighterImageWrapperRight>
          <ChallengedImage src={challenged?.thumbnailUri} loser={isChallengedLoser} transform={challenged_img_transforms} />
        </FighterImageWrapperRight>
        <ChallengerName isVictor={isChallengerVictor} loser={isChallengerLoser} size={size}>
          {challenger?.name}
        </ChallengerName>
        <ChallengedName isVictor={isChallengedVictor} loser={isChallengedLoser} size={size}>
          {challenged?.name}
        </ChallengedName>
        <Loot size={size}>
          { String(battle?.loot).length > 3 
            ? <small>🤯</small> 
            : battle?.loot
          }
        </Loot>
        { battle?.mode === BATTLE_MODE.BOTH &&
          <BothMode>
            <LootIcon color='brand' size='100%' />
            <HeroIcon color='brand' size='100%' />
          </BothMode>
        }
        { battle?.mode === BATTLE_MODE.LOOT &&
          <LootMode>
            <LootIcon color='brand' size='100%' />
          </LootMode>
        }
      </Card>
    </Link>
  )
}

const Card = styled(Box)`
  --fs: ${props => props.width*0.0485}px;
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};
  margin: ${props => props.margin || 0};
  clip-path: polygon(97.9% 0.05%, 99.9% 4.6%, 99.9% 99.1%, 1.9% 99%, 0% 95.9%, 0% 0.05%);
  cursor: pointer;
  user-select: none;
  transition: all var(--transition-move-ms) ease-in-out;
  &:hover {
    transform: translateY(-6px);
  }
`

const FrameImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const FighterImageWrapperLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
`

const FighterImageWrapperRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
`

const ChallengerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${props => props.loser ? 'filter: grayscale(100%);' : ''}
  ${props => props.transform ? `transform: ${props.transform?.transform};` : ''}
`

const ChallengedImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  ${props => props.loser ? 'filter: grayscale(100%);' : ''}
  ${props => props.transform ? `transform: ${props.transform?.transform};` : ''}
`

const CardText = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ghostclan;
  font-size: ${props => props.size ? props.size/20 : 10}px;
  color: var(--color-brand);
  // border: 1px solid tomato;
  white-space: nowrap;
`

const ChallengerName = styled(CardText)`
  bottom: 3%;
  left: 2%;  
  height: 9%;
  width: 25%;
  max-width: 25%;
  ${props => props.loser ? 'text-decoration: line-through;' : ''}
`

const ChallengedName = styled(CardText)`
  top: 1.8%;
  right: 1.9%;
  height: 9%;
  width: 25%;
  max-width: 25%;
  ${props => props.loser ? 'text-decoration: line-through;' : ''}
`

const Loot = styled(CardText)`
  justify-content: center;
  bottom: 2.5%;
  right: 0.8%;
  height: 11%;
  width: 10%;
  max-width: 10%;
  small {
    margin-top: 10%;
  }
`

const BattleMode = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LootMode = styled(BattleMode)`
  right: 10%;
  bottom: 3.5%;
  width: 9%;
  height: 9%;
`

const BothMode = styled(BattleMode)`
  right: 10.7%;
  bottom: 4%;
  width: 7.5%;
  height: 7.5%;
  svg:first-child {
    margin-right: -2px;
  }
`
