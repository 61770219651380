import { useState, useCallback } from 'react'
import { Box } from 'components'

export default function Img({ 
  src, 
  size, 
  height,
  width,
  ratio, 
  transforms,
  Component, 
  ComponentProps,
  ComponentLoader, 
  ComponentError, 
  isLoading, 
  isError,
  ...props
}) {

  const [ isLoadingImg, setIsLoadingImg] = useState(true)
  const onLoad = useCallback(() => setIsLoadingImg(false), [])

  width = size || width 
  height = size || height
  if (ratio) {
    width = width * ratio.width
    height = height * ratio.height
  }

  let style = {};
  if (isLoadingImg) style = Object.assign(style, styleLoading)
  if (transforms) style = Object.assign(style, transforms)

  return (
    <Box {...props}>
      { isLoading || isLoadingImg
          ? <ComponentLoader size={size} width={width} height={height} /> :
        isError 
          ? <ComponentError  size={size} width={width} height={height} /> : 
        null
      }
      { !isError && 
        <Component
          src={src} 
          alt='img' 
          width={`${width}px`}
          height={`${height}px`}
          style={style}
          isLoading={isLoadingImg}
          onLoad={onLoad}
          {...ComponentProps}
        />
      }
    </Box>
  )
}

const styleLoading = { display: 'none' }
