import styled, { css, keyframes } from 'styled-components'
import { Hero } from 'shared/models'
import { CHAIN_MINISTRY_TOKEN_ADDRESS } from 'shared/constants'
import Box from '../Box'
import UnknownSkin from './images/SkinUnknown.webp'
import NoviceSkin from './images/SkinNovice.webp'
import TraineeSkin from './images/SkinTrainee.webp'
import ExperiencedSkin from './images/SkinExperienced.webp'
import MasterSkin from './images/SkinMaster.webp'
import MinistrySkin from './images/SkinMinistry.webp'

const defaultSize = 300
const sizeRatio = 0.64403337271
const numLettersFit = 10

export const getSkinImage = (hero) => {
  if (hero.token_address === CHAIN_MINISTRY_TOKEN_ADDRESS)
    return MinistrySkin
  const rank = hero.getRank()
  return skinImage[rank]
}

export const skinImage = {
  [Hero.RANK.UNKNOWN]: UnknownSkin,
  [Hero.RANK.NOVICE]: NoviceSkin,
  [Hero.RANK.TRAINEE]: TraineeSkin,
  [Hero.RANK.EXPERIENCED]: ExperiencedSkin,
  [Hero.RANK.MASTER]: MasterSkin,
}

const float = keyframes`
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
`

const nameSlideIn = numLetters => keyframes`
  0% {
    left: 0%;
  }
  100% {
    left: -${numLetters*7}%;
  }
`

// Trick to use "box-shadow" and "clip-path" together
//   https://css-tricks.com/using-box-shadows-and-clip-path-together/
export const CardShadowWrap = styled.span`
  ${props => props.isTurn &&
    'filter: drop-shadow(0px 0px 32px rgba(0, 255, 224, 0.75))'
  };
`

export const HeroNameAnimationBox = styled.span`
  position: absolute;
  top: 54.8%;
  left: 19%;
  width: 61%;
  height: 7.5%;
  display: flex;
  align-items: center;
  justify-content: ${props => props.numLetters > numLettersFit ? 'flex-start' : 'center'}; 
  overflow: hidden;
`

export const HeroName = styled.span`
  position: absolute;
  font-size: var(--fs-name);
  white-space: nowrap;
  overflow: hidden;
  color: white;
`

export const Card = styled(Box)`
  --fs-name: ${props => props.size*0.058}px;
  --fs-stats: ${props => props.size*0.040}px;
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size * sizeRatio}px;
  cursor: pointer;
  font-family: ghostclan;
  clip-path: polygon(92.7% 0, 100% 3.9%, 100% 100%, 0 100%, 0 0);
  transition: all var(--transition-move-ms) ease-in-out;
  animation: ${props => props.isTurn
    ? css`${float} 5s ease-in-out infinite;`
    : 'inherit'
  };
  ${props => props.withHoverAnimation && `
    &:hover {
      transform: translateY(-6px);
    }
  `}
  ${props => props.withHoverAnimation && (props.name.length > numLettersFit) &&
    css`
      &:hover ${HeroName} {
        animation: ${nameSlideIn(props.name.length)} 4s ease-out;
      }
    `
  }
`

export const FrameImage = styled.img`
  z-index: 0;
  height: 100%;
  width: 100%;
`

export const HeroImageBox = styled.div`
  position: absolute;
  top: calc(31.2% - ${props => props.size/2}px);
  left: calc(50% - ${props => props.size/2}px);
  z-index: -1;
`

export const HeroImage = styled.img`
  ${props => props.isBW &&
    'filter: grayscale(1)'
  };
`

export const Attribute = styled.span`
  position: absolute;
  bottom: 3%;
  height: 4.5%;
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: var(--fs-stats);
`

export const Strength = styled(Attribute)`
  left: 1.9%;
`

export const Health = styled(Attribute)`
  right: 1.9%;
`

export const Stats = styled.span`
  position: absolute;
  top: 63.7%;
  left: 19%;
  width: 61.5%;
  height: 23.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  padding: 2%;
`

export function calcSize(height, width) {
  if (height === undefined && width === undefined) {
    height = defaultSize
    width = height * sizeRatio
  }
  else if (height === undefined) {
    height = width / sizeRatio
  }
  // else if (width === undefined) {
  //   width = height * sizeRatio
  // }
  // else {
  //   // all defined 
  // }
  return height
}
