import { OpKind } from '@taquito/taquito'
import { nanoid } from 'nanoid'
import { notify } from 'shared/utils' 
import { notify as notify_api } from 'shared/api'
import { Tezos } from './wallet'

export async function summonNFT({ dappAddress, walletAddress, tokenAddress, tokenId, name='', cost, owners }) {
  const tokenContract = await Tezos.wallet.at(tokenAddress)
  const dappContract = await Tezos.wallet.at(dappAddress)
  const _cost = Object.keys(owners).indexOf(walletAddress) >= 0 ? 0 : cost

  // const methods = dappContract.parameterSchema.ExtractSignatures()
  // console.log(methods)
  /*
When interacting with contract entrypoint I find “contract.methods.entrypoint().getSignature()” or “contract.methodsObject.entrypoint().getSignature()” helpful to get the format that entrypoint needs

Documentation for more context
https://tezostaquito.io/docs/next/smartcontracts#choosing-between-the-methods-or-methodsobject-members-to-interact-with-smart-contracts
*/

  const batchOp = await Tezos.wallet.batch([
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: walletAddress,
            operator: dappAddress,
            token_id: tokenId
          }
        }
      ]).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...dappContract.methods.summon_hero(
        name.toUpperCase(), 
        tokenAddress, 
        tokenId
      ).toTransferParams(),
      amount: _cost
    },
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          remove_operator: {
            owner: walletAddress,
            operator: dappAddress,
            token_id: tokenId
          }
        }
      ]).toTransferParams()
    },
  ])
  const batch = await batchOp.send()
  await batch.confirmation()
}

export async function challengeHero({ network, dappAddress, challenger, challenged, loot, mode }) {
  const bid = nanoid(12)
  const lootMutez = loot ? loot * 1000000 : 0
  const sendParams = loot ? { amount: loot } : null
  const contract = await Tezos.wallet.at(dappAddress)
  const op = await contract.methods.challenge_hero(
    bid,
    challenged.owner,
    challenger.owner,
    [
      [
        challenged.token_address, 
        challenged.token_id,
      ],
      [
        challenger.token_address, 
        challenger.token_id,
      ]
    ],
    lootMutez,
    mode,
  ).send(sendParams)
  await op.confirmation()
  await notify(network, challenger.owner, challenged.owner, 'challenge', `${challenger.name} challenges ${challenged.name}`, `/battles/${bid}`)
}

export async function acceptChallenge({ dappAddress, bid, loot, challenger, challenged, network }) {
  const contract = await Tezos.wallet.at(dappAddress)
  const op = await contract.methods.accept_challenge(bid)
    .send({ amount: loot })
  await op.confirmation()
  await notify(network, challenged.owner, challenger.owner, 'accept-challenge', `${challenged.name} accepted your challenge!`, `/battles/${bid}`)
  const CHAINBORN_BASE_URI = window.location.origin 
  const payload = {
    receiver : challenger.owner,
    short: `${challenged.name} accepted your challenge!`,
    subject: `${challenged.name} accepted your challenge!`,
    text: `${challenged.name} has accepted your challenge in battle ${CHAINBORN_BASE_URI}/battles/${bid} !!

  Now you fight!

  --
  The ChainBorn Team <3
  You can change your notification settings here:
  ${CHAINBORN_BASE_URI}/player/${challenger.owner}/settings
  `
  }
  await notify_api(payload)
}

export async function cancelChallenge({ dappAddress, bid }) {
  const contract = await Tezos.wallet.at(dappAddress)
  const op = await contract.methods.cancel_challenge(bid)
    .send()
  await op.confirmation()
}

export async function unsuitHero({ dappAddress, tokenAddress, tokenId }) {
  const dappContract = await Tezos.wallet.at(dappAddress)
  const batchOp = await Tezos.wallet.batch()
    .withContractCall(dappContract.methods.unsuit(
      tokenAddress, 
      tokenId,
    ))
  const batch = await batchOp.send()
  await batch.confirmation()
}

export async function suitUpHero({ dappAddress, tokenAddress, walletAddress, tokenId }) {
  const dappContract = await Tezos.wallet.at(dappAddress)
  const tokenContract = await Tezos.wallet.at(tokenAddress)
  const batchOp = await Tezos.wallet.batch([
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: walletAddress,
            operator: dappAddress,
            token_id: tokenId
          }
        }
      ]).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...dappContract.methods.suit_up(
        tokenAddress, 
        tokenId,
      ).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          remove_operator: {
            owner: walletAddress,
            operator: dappAddress,
            token_id: tokenId
          }
        }
      ]).toTransferParams()
    },
  ])
  const batch = await batchOp.send()
  await batch.confirmation()
}

export async function updateHeroCharacter({ dappAddress, tokenAddress, tokenId, name='', story, battleReady }) {
  const dappContract = await Tezos.wallet.at(dappAddress)
  const batchOp = await Tezos.wallet.batch()
    .withContractCall(dappContract.methods.update_hero_character(
      battleReady,
      name.toUpperCase(),
      story,
      tokenAddress, 
      tokenId,
    ))
  const batch = await batchOp.send()
  await batch.confirmation()
}

export async function updateHeroAttributes({ dappAddress, tokenAddress, tokenId, values }) {
  const dappContract = await Tezos.wallet.at(dappAddress)
  const batchOp = await Tezos.wallet.batch()
  for (const attribute of Object.keys(values)) {
    if (values[attribute] > 0) {
      batchOp.withContractCall(dappContract.methods.update_hero_attributes(
        attribute,
        values[attribute],
        tokenAddress, 
        tokenId,
      ))
    }
  }
  const batch = await batchOp.send()
  await batch.confirmation()
}

export async function _useBattleItem({ contractAddress, bid, user_hero, target_hero, item }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const target_heroes = [[ target_hero.token_address, target_hero.token_id ]]
  const op = await contract.methods.use_battle_item(bid, user_hero.token_address, user_hero.token_id, item.token_address, item.token_id, target_heroes).send()
  await op.confirmation()
}

export async function resolveBattle({ dappAddress, bid }) {
  const contract = await Tezos.wallet.at(dappAddress)
  const op = await contract.methods.resolve_battle(bid).send()
  await op.confirmation()
}

export async function purchaseStoreItem({ storeAddress, legAddress, walletAddress, iid, amount=1 }) {
  const leg = await Tezos.wallet.at(legAddress)
  const store = await Tezos.wallet.at(storeAddress)
  let batch = Tezos.wallet.batch()
    .withContractCall(leg.methods.update_operators([{
      add_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
    Array.from(new Array(amount)).forEach(() => {
      batch.withContractCall(store.methods.store_purchase_item(iid))
    })
    batch.withContractCall(leg.methods.update_operators([{
      remove_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
  let op = await batch.send()
  await op.confirmation(1)
}

export async function updateHeroItems({ contractAddress, walletAddress, tokenAddress, hero, items_add, items_remove }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const tokenContract = await Tezos.wallet.at(tokenAddress)
  const operator_add_operations = [].concat(items_add, items_remove).map(item => {
    return {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: item.token_id 
          }
        }
      ]).toTransferParams()
    }
  })
  const operator_remove_operations = [].concat(items_add, items_remove).map(item => {
    return {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          remove_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: item.token_id
          }
        }
      ]).toTransferParams()
    }
  })
  const unequip_operations = items_remove.map(item => {
    return {
      kind: OpKind.TRANSACTION,
      ...contract.methods.unequip_hero_item(
        item.token_address, 
        item.token_id, 
        hero.token_address, 
        hero.token_id
      ).toTransferParams()
    }
  })
  const equip_operations = items_add.map(item => {
    return {
      kind: OpKind.TRANSACTION,
      ...contract.methods.equip_hero_item(
        item.token_address, 
        item.token_id, 
        hero.token_address, 
        hero.token_id
      ).toTransferParams()
    }
  })
  const batchOp = await Tezos.wallet.batch([].concat(operator_add_operations, unequip_operations, equip_operations, operator_remove_operations))
  const batch = await batchOp.send()
  const op = await batch.confirmation()
  console.log(op.hash)
}

export async function unequipHeroItem({ contractAddress, uid, iid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.unequip_hero_item(
    iid.token_address, 
    iid.token_id, 
    uid.token_address, 
    uid.token_id
  ).send()
  console.log(op.hash)
}

export async function useHeroItem({ contractAddress, walletAddress, uid, iid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const tokenContract = await Tezos.wallet.at(iid.token_address)
  const batchOp = await Tezos.wallet.batch([
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: iid.token_id
          }
        }
      ]).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...contract.methods.use_hero_item(
        iid.token_address, 
        iid.token_id, 
        uid.token_address, 
        uid.token_id
      ).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          remove_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: iid.token_id
          }
        }
      ]).toTransferParams()
    },
  ])
  const batch = await batchOp.send()
  const op = await batch.confirmation()
  console.log(op.hash)
}

export async function equipHeroSkin({ contractAddress, walletAddress, uid, sid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const tokenContract = await Tezos.wallet.at(sid.token_address)
  const batchOp = await Tezos.wallet.batch([
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          add_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: sid.token_id
          }
        }
      ]).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...contract.methods.equip_hero_skin(
        sid.token_address, 
        sid.token_id, 
        uid.token_address, 
        uid.token_id
      ).toTransferParams()
    },
    {
      kind: OpKind.TRANSACTION,
      ...tokenContract.methods.update_operators([
        {
          remove_operator: {
            owner: walletAddress,
            operator: contractAddress,
            token_id: sid.token_id
          }
        }
      ]).toTransferParams()
    },
  ])
  const batch = await batchOp.send()
  const op = await batch.confirmation()
  console.log(op.hash)
}

export async function unequipHeroSkin({ contractAddress, uid, sid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.unequip_hero_skin(
    sid.token_address, 
    sid.token_id, 
    uid.token_address, 
    uid.token_id
  ).send()
  console.log(op.hash)
}

export async function marketplaceListHero({ contractAddress, price, uid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.marketplace_list_hero(
    price*1000000,
    uid.token_address,
    uid.token_id,
  ).send()
  console.log(op.hash)
}

export async function marketplaceListIitem({ storeAddress, walletAddress, price, iid, token_address, token_id }) {
  const uid = nanoid(8).replaceAll('_','-')
  const token = await Tezos.wallet.at(token_address)
  const store = await Tezos.wallet.at(storeAddress)
  const batch = await Tezos.wallet.batch()
    .withContractCall(token.methods.update_operators([{
      add_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: token_id
      }
    }]))
    .withContractCall(store.methods.marketplace_list_item(
      iid,
      price*1000000,
      uid,
    ))
    .withContractCall(token.methods.update_operators([{
      remove_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: token_id
      }
    }]))
  let op = await batch.send()
  await op.confirmation(1)
  console.log(op.hash)
}

export async function marketplaceCancelListing({ contractAddress, iid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.marketplace_cancel_listing(iid).send()
  console.log(op.hash)
}

export async function marketplaceCancelHeroListing({ contractAddress, uid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.marketplace_cancel_hero_listing(uid.token_address, uid.token_id).send()
  console.log(op.hash)
}

export async function marketplacePurchaseItem({ legAddress, storeAddress, walletAddress, iid }) {
  const leg = await Tezos.wallet.at(legAddress)
  const store = await Tezos.wallet.at(storeAddress)
  let batch = Tezos.wallet.batch()
    .withContractCall(leg.methods.update_operators([{
      add_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
    .withContractCall(store.methods.marketplace_purchase_item(iid))
    .withContractCall(leg.methods.update_operators([{
      remove_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
  let op = await batch.send()
  await op.confirmation(1)
}

export async function marketplacePurchaseHero({ legAddress, storeAddress, walletAddress, uid }) {
  const leg = await Tezos.wallet.at(legAddress)
  const store = await Tezos.wallet.at(storeAddress)
  let batch = Tezos.wallet.batch()
    .withContractCall(leg.methods.update_operators([{
      add_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
    .withContractCall(store.methods.marketplace_purchase_hero(uid.token_address, uid.token_id))
    .withContractCall(leg.methods.update_operators([{
      remove_operator: {
        owner: walletAddress,
        operator: storeAddress,
        token_id: 0
      }
    }]))
  let op = await batch.send()
  await op.confirmation(1)
}

export async function resolveBattleRound({ contractAddress }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.resolve_battle_round().send()
  console.log(op.hash)
}


export async function createTournament({ name, fee, contractAddress, maxHealth, maxStrength, maxCombined, allowItems, participants, additionalLoot }) {
  const tid = nanoid(12).replaceAll('_','-')
  const contract = await Tezos.wallet.at(contractAddress)
//  return console.log(contract.methods.create_tournament().getSignature())
  const op = await contract.methods.create_tournament(additionalLoot*1000000, allowItems, fee*1000000, maxCombined, maxHealth, maxStrength, name, participants, tid).send({ amount: additionalLoot })
  console.log(op.hash)
}

export async function cancelTournament({ contractAddress, tid }) { 
  const contract = await Tezos.wallet.at(contractAddress)
//  return console.log(contract.methods.cancel_tournament().getSignature())
  const op = await contract.methods.cancel_tournament(tid).send()
  console.log(op.hash)
}

export async function joinTournament({ contractAddress, token_address, token_id, amount, tid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.join_tournament(tid, token_address, token_id).send({ amount: amount })
  console.log(op.hash)
}

export async function leaveTournament({ contractAddress, token_address, token_id, tid }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.leave_tournament(tid, token_address, token_id).send()
  console.log(op.hash)
}

export async function tournamentResolveRound({ tid, contractAddress, bids_to_resolve }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const batchOp1 = await Tezos.wallet.batch()
  bids_to_resolve.forEach(bid => {
    batchOp1.withContractCall(contract.methods.resolve_round_battle(bid, tid))
  })
  if (bids_to_resolve.length > 0) {
    batchOp1.withContractCall(contract.methods.resolve_round(tid))
  }
  const op1 = await batchOp1.send()
  console.log(op1.opHash)
}

export async function tournamentStartNextRound({ tid, contractAddress, battles_to_create, bids_to_resolve }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const batch = await Tezos.wallet.batch()
  if (battles_to_create === 1) {
    batch.withContractCall(contract.methods.create_battle_for_next_round(tid))
  } else if (battles_to_create > 1) {
    batch.withContractCall(contract.methods.create_battle_for_next_round(tid))
    batch.withContractCall(contract.methods.create_battle_for_next_round(tid))
  } else {
    batch.withContractCall(contract.methods.resolve_tournament(tid))
  }
  const op = await batch.send()
  return op.hash
}

export async function claimMembershipRewards({ contractAddress }) {
  const contract = await Tezos.wallet.at(contractAddress)
  const op = await contract.methods.claim_membership_rewards().send()
  console.log(op.hash)
}
