import styled from 'styled-components'
import ReactIconStyled from './ReactIconStyled'
import { 
  GiTwoCoins, 
  GiCrestedHelmet,
  GiFlyingFlag,
  GiCrossedSwords,
  GiBattleMech,
  GiUpgrade,
  GiPointySword,
  GiStrong,
  GiNightSleep,
  GiAxeSword,
  GiPotionBall,
  GiCardJoker,
//  GiCrownedSkull,
  GiMetalHand,
  GiAbdominalArmor
} from 'react-icons/gi'
import {
  BiFace,
  BiLinkExternal,
  BiMedal,
  BiTrophy
} from 'react-icons/bi'
import {
  BsFillTrophyFill
} from 'react-icons/bs'
import {
  SiDatabricks
} from 'react-icons/si'
import { 
  FaDiscord,
  FaSkullCrossbones
} from 'react-icons/fa'
import {
  FiActivity
} from 'react-icons/fi'
import {
  MdOutlineSettingsSuggest,
  MdOutlineHealthAndSafety,
  MdCardMembership,
  MdNumbers,
} from 'react-icons/md'
import { IoMdHelp } from 'react-icons/io'
import { TbTournament } from 'react-icons/tb'
import { AiOutlineReload } from 'react-icons/ai'

export const ActivityIcon = styled(FiActivity)`${ReactIconStyled}`
export const AmountIcon = styled(MdNumbers)`${ReactIconStyled}`
export const MembershipCardIcon = styled(MdCardMembership)`${ReactIconStyled}`
export const BadgeIcon = styled(BiTrophy)`${ReactIconStyled}`
export const LeagueIcon = styled(BiMedal)`${ReactIconStyled}`
export const ItemHandIcon = styled(GiMetalHand)`${ReactIconStyled}`
export const ItemArmorIcon = styled(GiAbdominalArmor)`${ReactIconStyled}`
export const TournamentIcon = styled(TbTournament)`${ReactIconStyled}`
export const ReloadIcon = styled(AiOutlineReload)`${ReactIconStyled}`
export const LootIcon = styled(GiTwoCoins)`${ReactIconStyled}`
export const HeroIcon = styled(GiCrestedHelmet)`${ReactIconStyled}`
export const PlayerIcon = styled(BiFace)`${ReactIconStyled}`
export const ChallengeIcon = styled(GiFlyingFlag)`${ReactIconStyled}`
export const BattleIcon = styled(GiCrossedSwords)`${ReactIconStyled}`
export const SummonIcon = styled(GiBattleMech)`${ReactIconStyled}`
export const StrengthIcon = styled(GiStrong)`${ReactIconStyled}`
export const HealthIcon = styled(MdOutlineHealthAndSafety)`${ReactIconStyled}`
export const ExperienceIcon = styled(GiUpgrade)`${ReactIconStyled}`
export const NotBattleReadyIcon = styled(GiNightSleep)`${ReactIconStyled}`
export const AttackIcon = styled(GiPointySword)`${ReactIconStyled}`
export const PotionIcon = styled(GiPotionBall)`${ReactIconStyled} transform: rotateY(180deg);`
export const SkinsIcon = styled(GiCardJoker)`${ReactIconStyled}`
export const LinkIcon = styled(BiLinkExternal)`${ReactIconStyled}`
export const MetaIcon = styled(SiDatabricks)`${ReactIconStyled}`
export const ItemsIcon = styled(GiAxeSword)`${ReactIconStyled}`
export const DiscordIcon = styled(FaDiscord)`${ReactIconStyled}`
export const LoserIcon = styled(FaSkullCrossbones)`${ReactIconStyled}`
export const VictorIcon = styled(BsFillTrophyFill)`${ReactIconStyled}`
export const HelpIcon = styled(IoMdHelp)`${ReactIconStyled}`
export const SettingsIcon = styled(MdOutlineSettingsSuggest)`${ReactIconStyled}`

export {
  Cart as BuyIcon,
  Deploy as UpgradeIcon,
  Edit as EditIcon,
  AddCircle as AddIcon,
  Twitter as TwitterIcon,
  Youtube as YoutubeIcon,
  Github as GithubIcon,
  FormClose as CloseIcon,
  Chat as ChatIcon,
  Notification as NotificationIcon,
  ChatOption as StoryIcon,
  Menu as MenuIcon,
  Ascend as AscendIcon,
  Descend as DescendIcon,
  Calendar as DateIcon,
  Calendar as ChallengeDateIcon,
  SchedulePlay as StartDateIcon,
  Plan as FinishDateIcon,
  CaretRightFill as ArrowRightIcon,
  CaretLeftFill as ArrowLeftIcon,
  Filter as FilterIcon,
  InProgress as TimeoutIcon,
  Group as PublicIcon,
} from 'grommet-icons'
