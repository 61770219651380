import styled from 'styled-components'
import { Img, CircleLoader, CircleError } from 'components'
import { useGetHero } from 'shared/apollo'
import { 
  useResponsive, 
} from 'shared/utils'

const sizeBig = 128
const sizeSmall = 104

export default function BattleRowHero({ address, id, isLoser, size }) {

  const { loading, hero, error } = useGetHero({ address, id })
  const { width } = useResponsive()
  let imgSize = width > 480 ? sizeBig : sizeSmall
  if (size) imgSize = size

  return (
    <Wrapper>
      <Img 
        src={hero?.thumbnailUri} 
        size={imgSize}
        width={imgSize}
        height={imgSize}
        isLoading={loading} 
        isError={error} 
        Component={CircleImg}
        ComponentProps={{ isLoser: isLoser }}
        ComponentLoader={CircleLoader}
        ComponentError={CircleError}
      />
      <NameBox isLoser={isLoser}>
        {hero?.name}
      </NameBox>
    </Wrapper>
  )
}

const CircleImg = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: 50%;
  border: 2px solid var(--color-secondary); 
  ${props => props.isLoser ? 'filter: grayscale(100%);' : ''}
  @media only screen and (max-width: 480px) {
    height: ${props => props.height}px;
  }
  background-image: url(${props => props.src});
  background-size: cover;
  ${props => props.onLoad()}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

const NameBox = styled.div`
  position: absolute;
  z-index: 100;
  bottom: -4px;
  background-color: var(--color-secondary);
  padding: 2px 5px;
  font-family: 'ghostclan';
  font-size: 0.9em;
  border-radius: 5px;
  color: var(--color-brand);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100%); 
  ${props => props.isLoser ? 'text-decoration: line-through;' : ''}
`
