import { useRecoilValue } from 'recoil'
import { Box, TextInput, CheckBox, Text } from 'components'
import { walletState } from 'shared/state'

export default function PlayerInput({ value, checkboxLabel, margin, onChange }) {
  const wallet = useRecoilValue(walletState)
  return (
    <Box margin={margin}>
      <Box color='brand'>
        <TextInput
          placeholder='Player'
          value={value || ''}
          onChange={(event) => onChange(event.target.value)}
        />
      </Box>
      { wallet && checkboxLabel &&
        <Box color={wallet?.address === value ? 'brand' : '#777'} margin={{top:'small'}}>
          <CheckBox
            label={<Text weight='bold'>{checkboxLabel}</Text>}
            checked={wallet?.address === value}
            onChange={(event) => {
              if (event.target.checked) {
                onChange(wallet?.address)
              }
              else {
                onChange('')
              }
            }}
          />
        </Box>
      }
    </Box>
  )
}