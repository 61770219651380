import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { walletState } from 'shared/state'
import { useGetConfig } from 'shared/apollo'
import { setProvider, getActiveAccount } from 'shared/wallet'

export default function BootstrapConfig() {
  const { loading, config } = useGetConfig()
  const setWallet = useSetRecoilState(walletState)

  useEffect(() => {
    const init = async () => {
      if (!loading) {
        setProvider(config.RPC_ENDPOINT)
        setWallet(await getActiveAccount())
      }
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return null
}