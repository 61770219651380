import { Box, BattleCard } from 'components'
import { useGetBattle } from 'shared/apollo'

export default function BattleCardFetch(props) {

  const { battle, loading, error} = useGetBattle({ bid: props?.bid })

  if (loading || error) return (
    <Box>Unable to load Battle</Box>
  )

  return (
    <BattleCard battle={battle} {...props} />
  )
}
//          
