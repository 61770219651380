import styled from 'styled-components'
import { Box as GBox } from 'grommet'
import { Tip } from 'components'
import { CHAIN_MINISTRY_TOKEN_ADDRESS } from 'shared/constants'

export default function ExperienceProgressBar({ percentage, tip, height, rank, token_address }) {

  let dim = false
  if (rank.toLowerCase() === 'master') dim = true
  if (token_address === CHAIN_MINISTRY_TOKEN_ADDRESS) dim = true

  return (
    <Tip text={tip}>
      <ProgressBarOuter height={`${height}px`}>
        <ProgressBarInner height={`${height}px`} percentage={percentage} dim={dim} />
      </ProgressBarOuter>
    </Tip>
  )
}

const ProgressBarOuter = styled(GBox)`
  position: absolute;
  bottom: 5%;
  left: 22.8%;
  width: 53.8%;
`

const ProgressBarInner = styled(GBox)`
  width: ${props => `${props.percentage}%`};
  background-color: white;
  ${props => props.percentage < 8 
    ? `border-top-left-radius: 8px;
       border-bottom-left-radius: 8px;`
    : `border-radius: 8px;`
  };
  transition: width 1000ms;
  ${props => props.dim ? 'opacity: 0.1; background-color: var(--color-brand);' : ''}
`
