import { getIpfsLink, isEqualHero } from './utils'

const collectionsWithHover = [
  'KT1MsdyBSAMQwzvDH4jt2mxUKJvBSWZuPoRJ', // Neonz
]

export class Config {
  constructor(dataServer) {
    if (dataServer) {
      const config0 = dataServer?.config?.[0]?.config
      const config1 = dataServer?.config?.[0]?.storage?.config
      const config2 = dataServer?.config?.[0]?.items?.config
      this.CHAINBORN_LEG = config0?.CHAINBORN_LEG
      this.CHAINBORN_STORE = config0.CHAINBORN_STORE_ADDRESS
      this.CHAINBORN_ITEMS = config0.CHAINBORN_GAME_ITEMS_CONTROLLER_ADDRESS
      this.CHAINBORN_BADGE_BATTLES = config1.badgebattle_address
      this.CHAINBORN_ITEMS_USE = config2.items_use_address
      this.CHAINBORN_ITEMS_EQUIP = config2.items_equip_address
      this.CHAINBORN_ITEMS_SKINS = config2.skins_address
      this.CHAINBORN_REWARD_FAUCET = config0?.CHAINBORN_REWARD_FAUCET
      this.CHAINBORN_TOURNAMENTS_ADDRESS = config0.CHAINBORN_TOURNAMENTS_ADDRESS
      const badges = dataServer?.config?.[0]?.badges.battleround
      this.CHAINBORN_CONTRACT = config0.CHAINBORN_GAME_CONTROLLER_ADDRESS
      this.CHAINBORN_GAME_CONTROLLER_ADDRESS = config0.CHAINBORN_GAME_CONTROLLER_ADDRESS
      this.CHAINBORN_DATASTORE = config0.CHAINBORN_DATASTORE_ADDRESS
      this.NETWORK_NAME = config0.NETWORK_NAME
      this.RPC_ENDPOINT = config0.RPC_ENDPOINT
      this.leg_xrate = config0.leg_xrate / 100
      this.membership_rewards_amount = config0?.membership_rewards_amount
      this.membership_rewards_interval = config0?.membership_rewards_interval
      this.battle_experience_max  = config1.battle_experience_max
      this.battle_experience_min  = config1.battle_experience_min
      this.battle_turn_timeout  = config1.battle_turn_timeout
      this.hero_health_initial  = config1.hero_health_initial
      this.hero_strength_initial  = config1.hero_strength_initial
      this.hero_health_max = config1.hero_health_max
      this.hero_strength_max = config1.hero_strength_max
      this.looser_decide_faith_time  = config1.looser_decide_faith_time
      this.minimum_loot  = config1.minimum_loot / 1000000
      this.owners  = config1.owners
      this.pair_battle_cooldown = parseInt(config1.pair_battle_cooldown)
      this.paused  = config1.paused
      this.platform_loot_percentage  = config1.platform_loot_percentage
      this.randomizer_address  = config1.randomizer_address
      this.summon_cost = config1.summon_cost / 1000000
      this.supported_game_modes = config1.supported_game_modes
      this.hero_items_max = config2.hero_items_max
      this.supported_equip_exec_items = config2.supported_equip_exec_items
      this.supported_use_items = config2.supported_use_items
      this.badges = {
        ...badges,
        spoils: badges.spoils / 1000000
      }
    }
  }
}

export class StoreItem {

  constructor(itemServer) {
    if (itemServer) {
      this.mid = itemServer.mid 
      this.store_id = itemServer.mid.split('_').slice(1).join('_')
      this.name = itemServer?.item?.name
      this.mods = itemServer?.item?.mods
      this.item = itemServer?.item
      this.token_id = itemServer.token_id 
      this.token_address = itemServer.token_address
      this.amount_available = itemServer.amount_available
      this.item_offensive = itemServer.item_offensive
      this.item_power = itemServer.item_power
      this.item_type = itemServer.item_type
      this.item_category = itemServer.item_category
      this.item_price = itemServer.item_price / 1000000 
      this.item_market = itemServer.item_market
      this.metadata = itemServer.metadata
      this.displayUri = getIpfsLink(itemServer.metadata.displayUri)
      this.thumbnailUri = getIpfsLink(itemServer.metadata.thumbnailUri)
    }
  }

}

export class PlayerData {

  constructor(itemServer) {
    if (itemServer) {
      this.address = itemServer.playerdata?.address || ''
      this.meta = itemServer.playerdata?.meta || {}
      this.experience_total = itemServer.playerdata?.experience_total || 0
      this.experience_unspent = itemServer.playerdata?.experience_unspent || 0
      this.blacklisted = itemServer.blacklisted
      this.blacklisting = itemServer.blacklisting
    }
  }

}

export class PlayerStats {

  constructor(itemServer) {
    if (itemServer) {
      this.address = itemServer?.address || ''
      this.experience = itemServer?.experience || 0
      this.num_heroes = itemServer?.num_heroes || 0
      this.num_battles = itemServer?.num_battles || 0
    }
  }

}

export class LeagueStats {

  constructor(itemServer) {
    if (itemServer) {
      this.bid = itemServer?.bid
      this.victor = itemServer?.victor
      this.loser = itemServer?.loser
      this.year = itemServer?.year
      this.tournament_battle = itemServer?.tournament_battle
    }
  }

}

export class MinistryVictorLoot {

  constructor(itemServer) {
    if (itemServer) {
      this.bid = itemServer.bid
      this.victor = itemServer.victor
      this.reward = itemServer.reward 
      this.reward_amount = itemServer.reward_amount 
      this.operation_hash = itemServer.operation_hash
    }
  }

}

export class Tournament {

  constructor(itemServer) {
    if (itemServer) {
      this.address = itemServer?.address
      this.synced_at = itemServer?.synced_at
      this.admins = itemServer?.tournament?.admins
      this.config = itemServer?.tournament?.config
      this.heroes = itemServer?.tournament?.heroes
      this.rounds = itemServer?.tournament?.rounds
    }
  }

}

export class Legs {

  constructor(itemServer) {
    if (itemServer) {
      this.address = itemServer?.address
      this.balance = (itemServer?.balance || 0) / 100000000
      this.rewards_claimed = itemServer?.rewards_claimed
    }
  }

}

export class InventoryItem {

  constructor(itemServer) {
    if (itemServer) {
      this.name = itemServer?.token_metadata?.name
      this.token_id = itemServer.token_id 
      this.token_address = itemServer.token_address
      this.token_editions = itemServer.token_editions
      this.metadata = itemServer.token_metadata
      this.displayUri = getIpfsLink(itemServer.token_metadata.displayUri)
    }
  }

}

export class Collection {

  constructor(collectionServer) {
    if (collectionServer) {
      this.address = collectionServer.address
      this.banner = getIpfsLink(collectionServer.banner)
      this.name = collectionServer.name
      this.lore = collectionServer.lore
      this.crest = getIpfsLink(collectionServer.crest)
      this.link = collectionServer.link
    }
  }

}

export class Hero {

  constructor(heroServer, leaderboard={}, leaderboardSponsor={}) {
    if (heroServer) {
      const owner = heroServer.token_owner.indexOf('tz') === 0 ? heroServer.token_owner : heroServer.hero_owner
      this.token_address = heroServer.token_address
      this.token_id = heroServer.token_id
      this.id = `${heroServer.token_address}__${heroServer.token_id}` 
      this.owner = owner 
      this.items = heroServer.hero.items
      this.thumbnailUri = getIpfsLink(heroServer.token_metadata.thumbnailUri)
      if (collectionsWithHover.find(address => address === this.token_address)) {
        this.hoverUri = getIpfsLink(heroServer.token_metadata.displayUri)
      }
      this.name = heroServer.hero.character.name
      this.story = heroServer.hero.character.story
      this.battle_ready = heroServer.hero.character.battle_ready
      this.suited = heroServer.hero.suited
      this.health = parseInt(heroServer.hero.attrs.health)
      this.strength = parseInt(heroServer.hero.attrs.strength)
      this.experience = parseInt(heroServer.hero.attrs.experience)
      this.experience_total = parseInt(heroServer.hero.attrs.experience_total)
      this.battling = heroServer.hero.battling
      this.battles = heroServer.hero.battles
      this.wins = heroServer.wins || 0
      this.losses = heroServer.losses || 0
      // Badges - All Time
      this.badgesAllTime = {}
      for (const badgeName in leaderboard) {
        this.badgesAllTime[`${badgeName}Rank`] = 
          isEqualHero(heroServer, leaderboard[badgeName][0]) ? 1 :
          isEqualHero(heroServer, leaderboard[badgeName][1]) ? 2 :
          isEqualHero(heroServer, leaderboard[badgeName][2]) ? 3 : null
        if (this.badgesAllTime[`${badgeName}Rank`]) {
          this.badgesAllTime[`${badgeName}Num`] = leaderboard[badgeName][this.badgesAllTime[`${badgeName}Rank`]-1][badgeName]
        }
      }
      // Badges - Sponsored
      this.badgesSponsor = null
      //for (const badgeName in leaderboardSponsor) {
      //  this.badgesSponsor = this.badgesSponsor || {}
      //  this.badgesSponsor[`${badgeName}Rank`] = 
      //    isEqualHero(heroServer, leaderboardSponsor[badgeName][0]) ? 1 :
      //    isEqualHero(heroServer, leaderboardSponsor[badgeName][1]) ? 2 :
      //    isEqualHero(heroServer, leaderboardSponsor[badgeName][2]) ? 3 : null
      //  if (this.badgesSponsor[`${badgeName}Rank`]) {
      //    this.badgesSponsor[`${badgeName}Num`] = leaderboardSponsor[badgeName][this.badgesSponsor[`${badgeName}Rank`]-1].value
      //  }
      //}
    }
    this.skin = heroServer.hero.skin
    this.token_name = heroServer.token_metadata.name
    this.playing_tournament = heroServer?.hero?.playing_tournament
  }

  static merge(hero1, hero2) {
    return Object.assign(new Hero(), hero1, hero2)
  }

  static RANK = {
    UNKNOWN: 'Unknown',
    NOVICE: 'Novice',
    TRAINEE: 'Trainee',
    EXPERIENCED: 'Experienced',
    MASTER: 'Master',
  }

  static getRank(experience) {
    const xp = experience || 0
    if (xp < 25)  return Hero.RANK.UNKNOWN
    if (xp < 50)  return Hero.RANK.NOVICE
    if (xp < 75)  return Hero.RANK.TRAINEE
    if (xp < 100) return Hero.RANK.EXPERIENCED
    return Hero.RANK.MASTER
  }

  getRank(experience) {
    return Hero.getRank(this.experience_total)
  }

  copy(hero) {
    for (const key of Object.keys(hero)) {
      this[key] = hero[key]
    }
  }

}

export class PossibleHero {

  constructor(possibleHeroServer) {
    if (possibleHeroServer) {
      this.token_address = possibleHeroServer.token_address
      this.token_id = possibleHeroServer.token_id
      this.owner = possibleHeroServer.token_owner
      this.thumbnailUri = getIpfsLink(possibleHeroServer.token_metadata.thumbnailUri)
      if (collectionsWithHover.find(address => address === this.token_address)) {
        this.hoverUri = getIpfsLink(possibleHeroServer.token_metadata.displayUri)
      }
      this.nft = {
        name: possibleHeroServer.token_metadata.name,
        description: possibleHeroServer.token_metadata.description,
      }
    }
  }

}

export class Battle {

  constructor(battleServer, config) {
    this.bid = battleServer.bid
    this.challenger_owner = battleServer.challenger_owner
    this.challenged_owner = battleServer.challenged_owner
    this.challenge_time = battleServer.battle.challenge_time
    this.challenger = battleServer.battle.challenger
    this.challenger_damage = battleServer.battle.challenger_damage
    this.challenged = battleServer.battle.challenged
    this.challenged_damage = battleServer.battle.challenged_damage
    this.experience_gained = battleServer.battle.experience_gained
    this.finish_time = battleServer.battle.finish_time
    this.finished = battleServer.battle.finished
    this.looser = battleServer.battle.looser
    this.loot = battleServer.battle.loot / 1000000
    this.mode = battleServer.battle.mode
    this.resolved = battleServer.battle.resolved
    this.start_time = battleServer.battle.start_time
    this.started = battleServer.battle.started
    // Calc turn
    this.turn_player = battleServer?.offchain_battle?.turn_player || battleServer.battle.turn_player
    this.turn = this.turn_player === this.challenger_owner ? 
      { nat: battleServer.challenger_token_id, address: battleServer.challenger_token_address } : 
      { nat: battleServer.challenged_token_id, address: battleServer.challenged_token_address } 
    // Calc turns
    this.turns = battleServer.battle.turns
    if (battleServer?.offchain_battle?.turns) {
      this.turns = { 
        turns: battleServer.offchain_battle.turns, 
        latest: battleServer.offchain_battle?.latest || battleServer?.battle?.start_time
      } 
    }
    this.onchain_battle = battleServer.battle
    this.offchain_battle = battleServer.offchain_battle
    this.victor = battleServer.battle.victor
    this.cancelled = battleServer.cancelled || false
    this.turn_timeout = config?.battle_turn_timeout
    this.version = battleServer?.battle?.version
    this.victor_player = battleServer?.battle?.victor_player
    this.loser_player = battleServer?.battle?.loser_player
  }

  isTimeout() {
    if (!this.turns) return false
    const timeNow = (new Date()).getTime() / 1000
    const timeLatestTurn = (new Date(this.turns.latest)).getTime() / 1000
    return (timeNow - timeLatestTurn) > this.turn_timeout
  }

  getStatus() {
    if (this.started && !this.finished && this.isTimeout()) {
      return 'Timeout'
    }
    if (this.resolved) return 'Resolved'
    if (this.finished) return 'Finished'
    if (this.started) return 'Ongoing'
    return 'Challenge'
  }

}

