import { Link as WLink } from 'wouter'
import styled from 'styled-components'

export default function Link({ enabled=true, children, ...props}) {
  return (
    enabled 
    ? <WLink {...props}>
        <A>{children}</A>
      </WLink>
    : children
  )
}

const A = styled.a`
  color: inherit;
`
