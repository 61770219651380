import { useCallback } from 'react'
import styled from 'styled-components'
import { Box, Text, Clock } from 'components'

export default function Timer({ secondsLeft, direction='column', color='status-error', onZeroTick }) {

  const seconds = Math.floor(secondsLeft % 60)
  const minutes = Math.floor((secondsLeft % 3600) / 60)
  const hours = Math.floor((secondsLeft % (3600 * 24)) / 3600)
  const days = Math.floor(secondsLeft / (3600 * 24))

  const onChange = useCallback((time) => {
    if (time === 'T0:0:0' && typeof onZeroTick === 'function') {
      onZeroTick()
    }
  }, [onZeroTick])

  return (
    <Root direction={direction}>
      <Text color={color} textAlign='center'>
        { days === 1 && `${days} day` }
        { days  >  1 && `${days} days` }
      </Text>
      { days >=1 && direction === 'row' &&
        <Text color={color}>, &nbsp;</Text>
      }
      <Clock 
        type='digital'
        run='backward'
        color={color}
        time={`22T${pad(hours)}:${pad(minutes)}:${pad(seconds)}`} 
        onChange={onChange}
      />
    </Root>
  )
}

function pad(number) {
  return String(number).padStart(2, '0')
}

const Root = styled(Box)`
  font-family: ghostclan;
`
