export const getFBToken = async (payload) => {
  const res = await fetch('/rest/fbtoken', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
  const token = await res.text()
  return token
}

export const login = async (payload) => {
  const res = await fetch('/rest/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  })
  const token = await res.text()
  return token
}

export const executeBattleTurn = async (payload) => {
  const res = await fetch('/rest/execute', {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(payload)
  })
  let text = await res.text()
  if (!res.ok) throw new Error(text)
  return text
}

export const getTezIDProfile = async (address) => {
  if (!address) return null
  const res = await fetch(`https://tezid.net/api/mainnet/profile/${address}`)
  const profile = await res.json()
  return profile
}

export const getTezIDProofs = async (address) => {
  if (!address) return null
  const res = await fetch(`https://tezid.net/api/mainnet/proofs/${address}`)
  const proofs = await res.json()
  return proofs
}

export const getTezIDChannels = async (address) => {
  if (!address) return null
  const res = await fetch(`https://tezid.net/api/mainnet/notifications/${address}`)
  const channels = await res.json()
  return channels
}

export const getSettings = async (address) => {
  if (!address) return null
  const res = await fetch(`/rest/settings/${address}`)
  if (res.ok) {
    const settings = await res.json()
    return settings
  } else {
    const text = await res.text()
    throw new Error(text)
  }
}

export const postSettings = async (address, payload) => {
  if (!address) return null
  const res = await fetch(`/rest/settings/${address}`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(payload)
  })
  if (res.ok) {
    const settings = await res.json()
    return settings
  } else {
    const text = await res.text()
    throw new Error(text)
  }
}

export const notify = async (payload) => {
  if (!payload) return null
  const res = await fetch(`/rest/notify`, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(payload)
  })
  const text = await res.text()
  if (!res.ok) throw new Error(text)
  return text
}
