import { useState } from 'react'
import { Link, Img, BattleIcon, TournamentIcon, NotBattleReadyIcon } from 'components'
import { Hero } from 'shared/models'
import { 
  getIpfsLink,
  getHeroImgSize,
  getHeroIconSize,
  getHeroImgRatio,
  getHeroImgTransforms 
} from 'shared/utils'
import { CircleLoader, CircleError } from '../Loaders/Circle'
//import Badges from './Badges'
import ExperienceProgressBar from './ExperienceProgressBar'
import BattleProgressBar from './BattleProgressBar'
import PotionBelt from './PotionBelt'
import { 
  CardShadowWrap, 
  Card, 
  FrameImage, 
  HeroImageBox, 
  HeroImage, 
  HeroNameAnimationBox, 
  HeroName, 
  Strength, 
  Health, 
  Stats, 
  getSkinImage,
  calcSize,
} from './index.styled'

export default function HeroCard({ hero, showProgress, showPotionBelt, 
                                                                    isTurn, 
                                                                    margin, 
                                                                    height, 
                                                                    width, 
                                                                    droppableItem, 
                                                                    battle,
                                                                    attributeModifications={}, 
                                                                    withLink=true, 
                                                                    withHoverAnimation=true }) {
  const [ isMouseHover, setIsMouseHover ] = useState(false)
  const size = calcSize(height, width)
  const imgSize = getHeroImgSize(hero, size)
  const iconSize = getHeroIconSize(size)
  const imgRatio = getHeroImgRatio(hero)
  const imgTransforms = getHeroImgTransforms(hero, size)

  const experienceProgressbarHeight = size * 0.031
  const battleProgressbarHeight = size * 0.1
  const damagePercentage = hero.damage ? Math.round((hero.damage / hero.health) * 100) : 0 
  const healthPercentage = Math.max(0, 100 - damagePercentage)
  const sumAttrMods = () => {
    return Object.values(attributeModifications).reduce((s,v) => s+v, 0)
  }
  const rankPercentage = 100 / (Object.keys(Hero.RANK).length-1)
  const experience_total = hero.experience_total + sumAttrMods()
  const experiencePercentage = 
    experience_total >= 100 
      ? 100 
      : Math.round((experience_total % rankPercentage) / rankPercentage * 100)
  const currentRank = hero.getRank()
  const nextRank = Hero.getRank(hero.experience_total + rankPercentage)

  const onSetIsMouseHover = (bool) => () => {
    if (hero.hoverUri) {
      setIsMouseHover(bool)
    }
  }

  const getAttrMod = (attr) => {
    return attributeModifications[attr] || 0
  }

  const health = hero.health + getAttrMod('health')
  const strength = hero.strength + getAttrMod('strength')

  return (
    <Link href={`/heroes/${hero.token_address}/${hero.token_id}`} enabled={withLink}>
      <CardShadowWrap isTurn={isTurn}>
        <Card 
          name={hero?.name}
          size={size}
          margin={margin}
          isTurn={isTurn} 
          onMouseEnter={onSetIsMouseHover(true)}
          onMouseLeave={onSetIsMouseHover(false)}
          withHoverAnimation={withHoverAnimation}
          >
          <FrameImage src={hero.skin ? getIpfsLink(hero.skin.uri) : getSkinImage(hero)} />
          <HeroImageBox size={imgSize}>
            <Img
              src={isMouseHover ? hero.hoverUri : hero.thumbnailUri} 
              size={imgSize} 
              ratio={imgRatio}
              transforms={imgTransforms}
              Component={HeroImage} 
              ComponentProps={{ isBW: !hero.suited }}
              ComponentLoader={CircleLoader} 
              ComponentError={CircleError} 
            />
          </HeroImageBox>
          <HeroNameAnimationBox numLetters={hero.name?.length}>
            <HeroName numLetters={hero.name?.length}>
              {hero.name}
            </HeroName>
          </HeroNameAnimationBox>
          <Strength>{strength}</Strength>
          <Health>{health}</Health>
          <Stats>
            { !hero.battle_ready &&
              <NotBattleReadyIcon size={iconSize} color='white' />
            }
            { hero.battling &&
              <BattleIcon size={iconSize} color='white' />
            }
            { !hero.battling && hero.playing_tournament &&
              <TournamentIcon size={iconSize} color='white' />
            }
          </Stats>
          <ExperienceProgressBar 
            tip={`${experiencePercentage}% XP for ${nextRank}`}
            rank={currentRank}
            height={experienceProgressbarHeight} 
            token_address={hero?.token_address}
            percentage={experiencePercentage}
          />
        </Card>
      </CardShadowWrap>
      { showProgress &&
        <BattleProgressBar 
          height={battleProgressbarHeight} 
          percentage={healthPercentage}
        />
      }
      { showPotionBelt &&
        <PotionBelt 
          hero={hero}
          battle={battle}
        />
      }
    </Link>
  )
}
