import styled from 'styled-components'
import { Link, LinkIcon, Box } from 'components'

export default function LinkButton({ href, text, ...rest }) {
  return (
    <Root pad='small' {...rest}>
      <Link href={href}>
        { text }
        <LinkIcon size='22px' />
      </Link>
    </Root>
  )
}

const Root = styled(Box)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1.2em;
  color: var(--color-brand);
  background-color: var(--color-secondary);
  margin-left: 10px;
  a {
    color: var(--color-brand);
    text-decoration: none;
    font-weight: bold;
    display: flex;
  }
  svg {
    color: var(--color-brand);
    margin-left: 5px;
  }
`
