async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      const reg = await navigator.serviceWorker.register(`/notifications-sw.js`, { scope: '/notifications' })
      reg.update()
    } catch(e) {
      console.error('Unable to register serviceworker', e)
    }
  }
}
registerServiceWorker()

