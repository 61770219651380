import { TextInput as GTextInput } from 'grommet'
import styled from 'styled-components'

export default function TextInput(props) {
  return <TextInputStyled type="number" {...props} />
}

const TextInputStyled = styled(GTextInput)`
  ${props => props.readOnly && `
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & {
      -moz-appearance:numberfield;
    }
  `}
`
