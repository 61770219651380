import { useLocation } from 'wouter'
import { Box, Heading as GHeading } from 'grommet'
import styled from 'styled-components'
import Link from '../Link'
import Tip from '../Tip'

const Heading = styled(GHeading)`
  padding: 8px 16px;
  background: var(--color-secondary);
  cursor: pointer;
  font-family: 'ghostclan';
  font-size: 22px;
  user-select: none;
`;

export default function HeaderButton({ href, Icon, children, tip, onClick=() => {} }) {

  const location = useLocation()

  const hrefParts = href.split('?')[0].split('/')
  const locationParts = location?.[0].split('?')[0].split('/')

  const color = 
    hrefParts.length > 1 && 
    locationParts.length > 1 && 
    hrefParts[1] === locationParts[1] 
      ? 'brand' 
      : 'brandMiddle'

  const TTip = tip ? Tip : NTip

  return (
    <Link href={href} onClick={onClick}>
      <TTip text={tip} size='medium'>
        { Icon ?
          <IconWrapper>
            <Icon color={color} size='30px' />
          </IconWrapper>
          :
          <Heading level='2' size='small' margin='none' color={color}>
            { children }
          </Heading>
        }
      </TTip>
    </Link>
  )
}

const IconWrapper = styled(Box).attrs({
  align:'center',
  justify: 'center',
  round: 'large',
  background: 'secondary'
})`
  width: 48px;
  height: 48px;
`

const NTip = styled.div`
`
