import styled from 'styled-components'

export const SliderRoot = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`

export const SlideContainer = styled.div`
  display: flex;
  transition: transform 300ms ease 100ms;
  ${props => props.shift !== 0 &&
    `transform: translate3d(${-props.shift}px, 0, 0);`
  }
`

const Button = styled.button`
  position: absolute;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.4);
  border: none;
  cursor: pointer;
`

export const PrevButton = styled(Button)`
  left: 0;
`

export const NextButton = styled(Button)`
  right: 0;
`
