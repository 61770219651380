import styled from 'styled-components'
import { Text, LootIcon, HeroIcon, TezosSign } from 'components'
import { BATTLE_MODE } from 'shared/constants'
import Box from '../Box'

export default function BattleInfoMobile({ battle }) {
  return (
    <Root>
      <Box direction='row' align='center'>
        { battle.mode === BATTLE_MODE.BOTH &&
          <HeroIcon />
        }
        <Box direction='row' gap='4px' >
          <LootIcon />
          <Text style={{ fontSize: '0.9em' }}>{battle.loot} <TezosSign /></Text>
        </Box>
      </Box>
      <Text className='status' weight='bold' style={{ fontFamily: 'ghostclan' }}>
        { battle.getStatus() }
      </Text>
    </Root>
  )
}

const Root = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px;
  align-items: center;
  margin-top: 12px;
  border-radius: 4px;
  background-color: var(--color-brand-light);
  color: black;
  @media (min-width: 480px) {
    display: none;
  }
`

