import styled from 'styled-components'

const TezosSignWrap = styled.span`
  font-family: 'Andika', sans-serif;
  transform: translateY(-1px);
  ${props => props.style}
`
export default function TezosSign({ ...props }) {
  return (
    <TezosSignWrap {...props}>Ł</TezosSignWrap>
  )
}
