import { useRef, useState } from 'react'
import { Menu as MenuIcon } from 'grommet-icons'
import { Box, ActionButton } from '../index'
import styled from 'styled-components'
import Wallet from '../Wallet'
import NotificationsButton from './NotificationsButton'
import { NotificationsDropTarget } from './DropTarget'
import { ROUTE_HEROES, ROUTE_BATTLES, ROUTE_BADGES, ROUTE_LEAGUE, ROUTE_STORE, ROUTE_HELP, ROUTE_TOURNAMENTS, ROUTE_PLAYERS } from './index'
import HeaderButton from './HeaderButton'

export default function HeaderMenuMobile({ onNotificationsClick }) {
  const notificationTarget = useRef()
  const [ showMenu, setShowMenu ] = useState(false)

  const handleToggleMenu = () => setShowMenu(!showMenu)

  return (
    <>
      <Box direction='row' align='center' justify='end'>
        <NotificationsButton 
          dropTarget={notificationTarget.current} 
          onClick={onNotificationsClick} 
        />
        <ActionButton icon={<MenuIcon color='brand' />} onClick={() => setShowMenu(!showMenu)} style={{
          marginLeft: '5px',
          marginRight: '5px'
        }}/> 
        { showMenu &&
          <MenuBox background='secondary'>
            <HeaderButton href={ROUTE_HEROES} onClick={handleToggleMenu}>Heroes</HeaderButton>
            <HeaderButton href={ROUTE_BATTLES} onClick={handleToggleMenu}>Battles</HeaderButton>
            <HeaderButton href={ROUTE_TOURNAMENTS} onClick={handleToggleMenu}>Tournaments</HeaderButton>
            <HeaderButton href={ROUTE_PLAYERS} onClick={handleToggleMenu}>Players</HeaderButton>
            <HeaderButton href={ROUTE_BADGES} onClick={handleToggleMenu}>Cup</HeaderButton>
            <HeaderButton href={ROUTE_LEAGUE} onClick={handleToggleMenu}>League</HeaderButton>
            <HeaderButton href={ROUTE_STORE} onClick={handleToggleMenu}>Store</HeaderButton>
            <HeaderButton href={ROUTE_HELP} onClick={handleToggleMenu}>Help</HeaderButton>
            <WalletWrapper onClick={handleToggleMenu}>
              <Wallet />
            </WalletWrapper>
          </MenuBox>
        }
      </Box>
      <NotificationsDropTarget ref={notificationTarget} />
    </>
  )
}

const MenuBox = styled.div`
  position: absolute;
  top: 55px;
  right: 17px; 
  @media (max-width: 768px) {
    right: 11px; 
  }
`

const WalletWrapper = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  background-color: black;
`
