import styled from 'styled-components'
import { SelectMultiple as GSelectMultiple } from 'grommet'

const SelectMultiple = styled(GSelectMultiple)`
  color: ${props => props.theme.global.colors.brand};
`

SelectMultiple.defaultProps = {
}

export default SelectMultiple
