import { Box, Button, Text, Modal } from 'components'

export default function ModalYesNo({ 
  isOpen, 
  text, 
  textColor,
  confirmText='Yes',
  rejectText='Nay',
  confirmIcon=null,
  rejectIcon=null,
  onConfirm,
  onReject 
}) {
  return (
    <Modal isOpen={isOpen} onClose={onReject}>
      <Text color={textColor}>
        { text }
      </Text>
      <Box direction='row' gap='small' margin={{ top: 'medium' }} >
        <Button 
          primary
          label={confirmText}
          icon={confirmIcon}
          onClick={onConfirm} 
        />
        <Button 
          label={rejectText}
          onClick={onReject}
        />
      </Box>
    </Modal>
  )
}
