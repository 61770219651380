import { useState } from 'react'
import { SizeMe } from 'react-sizeme'
import { ArrowRightIcon, ArrowLeftIcon} from '..'
import { SliderRoot, SlideContainer, PrevButton, NextButton } from './index.styled'

/*
* Slides elements with prev/next buttons
*/
export default function Slider({ contentWidth, enabled=true, children }) {
  const [ shift, setShift ] = useState(0)
  return (
    <SizeMe>
      {({ size }) => 
        <SliderRoot>
          <SlideContainer shift={shift}>
            { children }
          </SlideContainer>
          { enabled && shift > 0 &&
            <PrevButton onClick={() => setShift(prev => prev-size.width/2)}>
              <ArrowLeftIcon color='#fff'/>
            </PrevButton>
          }
          { enabled && size.width + shift < contentWidth  &&
            <NextButton onClick={() => setShift(prev => prev+size.width/2)}>
              <ArrowRightIcon color='#fff'/>
            </NextButton>
          }
        </SliderRoot>
      }
    </SizeMe>
    
  )
}
