import { useState } from 'react'
import { Img, RectangleLoader, RectangleError } from '../index'
import { 
  Card, 
  CardImage, 
  CardImg, 
  CardText, 
  CardName, 
  CardDescription 
} from './components'

export default function NFTCard({ address, id, name, description, thumbnailUri, hoverUri, isSummoning, onClick }) {
  const [ isMouseHover, setIsMouseHover ] = useState(false)

  const onSetIsMouseHover = (bool) => () => {
    if (hoverUri) {
      setIsMouseHover(bool)
    }
  }

  return (
    <Card 
      animation={isSummoning ? 'pulse' : ''} 
      onClick={onClick}
      onMouseEnter={onSetIsMouseHover(true)}
      onMouseLeave={onSetIsMouseHover(false)}
      >
      <CardImage>
        <Img 
          src={isMouseHover ? hoverUri : thumbnailUri} 
          width={208}
          height={208}
          Component={CardImg} 
          ComponentLoader={RectangleLoader} 
          ComponentError={RectangleError} 
        />
      </CardImage>
      <CardText>
        <CardName>{ name }</CardName>
        <CardDescription>{ description }</CardDescription>
      </CardText>
    </Card>
  )
}
